
const digest = '65eada90924218eba4e72a5f00291c3cb69387ecce1351e454cdefd066d4bcff';
const classes = {"container":"_container_smzpm_1","modal":"_modal_smzpm_18"};
const css = `._container_smzpm_1{align-items:center;backdrop-filter:blur(12px);background:rgba(0,0,0,.502);bottom:0;color:#fff;display:flex;height:100%;justify-content:center;left:0;overflow:hidden;position:fixed;right:0;top:0;width:100%;z-index:100000}._container_smzpm_1 ._modal_smzpm_18{align-items:center;background-color:#171a1f;border-radius:16px;display:flex;flex-direction:column;font-size:14px;height:auto;margin:16px;max-height:80%;max-width:100%;overflow:auto;padding:24px;position:relative;width:380px}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  