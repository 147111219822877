import styled from "styled-components";

export const Wrapper = styled.main`
    position: absolute;
    top: 90px;
    overflow-y: auto;
    padding: 60px 60px 0px 60px;
    box-sizing: border-box;
    width: 100%;
    overflow-x: hidden;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: ${({theme}) => theme.breakingPoints.lg}) {
        padding: 16px 16px 0px 16px;
    }

    @media (max-width: ${({theme}) => theme.breakingPoints.sm}) {
        &::-webkit-scrollbar { display: none; }
    }
`