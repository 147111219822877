import styled from "styled-components";

interface Props {
    $size: 'small' | 'medium'
}



export const Input = styled.input<Props>`
    padding: ${({$size}) => $size === 'small' ? '8px 16px' : '12px 16px'};
    font-size: ${({theme}) => theme.fontSize.text.md};
    line-height: ${({theme}) => theme.lineHeight.xs};
    border: 1px solid ${({theme}) => theme.color.grayscale[300]};
    border-radius: 16px;
    background-color: ${({theme}) => theme.color.grayscale[900]};
    color: ${({theme}) => theme.color.grayscale[100]};
    outline: none;
    letter-spacing: 0.5px;
    
    ::placeholder {
        color: ${({theme}) => theme.color.grayscale[300]};
    }
    
    &:hover{
        border-color: ${({theme}) => theme.color.grayscale[100]};
        ::placeholder {
            color: ${({theme}) => theme.color.grayscale[100]};
        }
    }
    
    &:focus {
        border-color: ${({theme}) => theme.color.brand.yellow.regular};
    }
    
    &:disabled {
        background-color: ${({theme}) => theme.color.grayscale[700]};
        color: ${({theme}) => theme.color.grayscale[400]};
        border-color: ${({theme}) => theme.color.grayscale[300]};
        ::placeholder {
            color: ${({theme}) => theme.color.grayscale[400]};
        }
    }
`

export const Label = styled.label.attrs({
    className: "text-sm"
})`
    //display: inline-block;
    padding-left: 16px;
    margin-bottom: 4px;
`

