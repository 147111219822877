import React from "react";
import Alert from "components/alert/Alert";
import Button from "components/button/Button";

const ApiKeys: React.FC = () => {
    return (
        <>
            <h3>API keys</h3>
            <p className={'text-sm'}>
                API keys are Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
            </p>
            <Alert type={'info'}>
                No API keys added yet. Feel free to add one.
            </Alert>
            <Button variant={'primary'}>
                Add new API key
            </Button>
        </>
    )
}

export default ApiKeys