import {TPagination} from "../types/types";
import React, {useCallback, useMemo} from "react";

export const DOTS = '...';

const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

export type TPaginationReturnType = {
    from: number;
    to: number;
    total: number;
    perPage: number;
    handlePerPage: (page: number) => void;
    handleNextPage: () => void;
    handlePreviousPage: () => void;
    isPreviousPageDisabled: boolean;
    isNextPageDisabled: boolean;
    setCurrentPage: (page: number) => void;
    numberOfPages: number;
    currentPage: number;
    paginationRange?: (string | number)[];
}

export const usePagination = (
    pagination: TPagination,
    setPagination:  (pagination: TPagination) => void,
    siblingCount = 1
): TPaginationReturnType => {
    const {totalItems, skip: page, limit} = pagination

    const from = (page ) * limit + 1;
    const to = Math.min((page + 1) * limit, totalItems);

    const handleNextPage = useCallback(() => {
        setPagination({
            ...pagination,
            skip: page + 1,
        });
    }, [pagination, setPagination, page]);

    const handlePreviousPage = useCallback(() => {
        setPagination({
            ...pagination,
            skip: page - 1,
        });
    }, [pagination, setPagination, page]);

    const isNextPageDisabled = (page + 1) * limit >= totalItems;
    const isPreviousPageDisabled = page === 0;

    const handlePerPage = useCallback((newLimit: number) => {
        setPagination({
            ...pagination,
            limit: newLimit,
            skip: 0, // Reset to the first page when limit changes
        });
    }, [pagination, setPagination]);

    const setCurrentPage = useCallback((newPage: number) => {
        setPagination({
            ...pagination,
            skip: newPage - 1,
        });

    }, [pagination, setPagination]);

    const numberOfPages = useMemo( () => Math.ceil(totalItems / limit), [totalItems, limit]);
    const currentPage = useMemo(() => Math.ceil(to / limit), [to, limit]);

    // Source: https://github.com/mayankshubham/react-pagination/blob/master/src/usePagination.js
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalItems / limit);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount);
        }

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        /*
          We do not want to show dots if there is only one position left
          after/before the left/right page count as that would lead to a change if our Pagination
          component size which we do not want
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [totalItems, limit, siblingCount, currentPage]);

    return {
        from,
        to,
        handleNextPage,
        handlePreviousPage,
        isNextPageDisabled,
        isPreviousPageDisabled,
        handlePerPage,
        total: totalItems,
        perPage: limit,
        setCurrentPage,
        numberOfPages,
        currentPage,
        paginationRange
    };
}