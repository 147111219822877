import styled, {css} from "styled-components";

interface Props {
    $variant: "primary" | "outlined" | "text" | "filled";
    $size: "small" | "large";
    $fullWidth?: boolean;
}

export const Wrapper = styled.button<Props>`
    width: ${({$fullWidth}) => $fullWidth ? '100%' : 'fit-content'};
    border-radius: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    line-height: ${props => props.theme.lineHeight.xs};
    font-size: ${props => props.theme.fontSize.text.lg};
    white-space: nowrap;
    min-width: fit-content;
    letter-spacing: 0.25px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({$size}) => {
        return css`
            padding: ${$size === 'small' ? '12px 16px' : '16px 32px'};
            letter-spacing: ${$size === 'small' ? '0.25px' : '0.5px'};
            height: ${$size === 'small' ? '48px' : '56px'};
        `
    }}
    
    ${({$variant, theme}) => {
        switch ($variant) {
            case 'primary':
                return css`
                    background: linear-gradient(90deg, #F1D665 0%, #F3745A 100%);
                    box-shadow: 0px 0px 12px 0px rgba(243, 116, 90, 0.24);
                    color: ${theme.color.grayscale[900]};
                    &:hover {
                        box-shadow: 0px 0px 16px 0px rgba(243, 116, 90, 0.56);
                    }
                    &:disabled {
                        pointer-events: none;
                        background: linear-gradient(90deg, rgba(241, 214, 101, 0.64) 0%, rgba(243, 116, 90, 0.64) 100%);
                        box-shadow: 0px 0px 12px 0px rgba(243, 116, 90, 0.24);
                    }
                `
            case 'outlined':
                return css`
                    background: transparent;
                    border: 2px solid ${theme.color.grayscale[300]};
                    color: ${theme.color.grayscale[100]};
                `
            case 'text':
                return css`
                    background: transparent;
                    color: ${theme.color.grayscale[100]};
                `
            case 'filled':
                return css`
                    background: ${theme.color.grayscale[600]};
                    color: ${theme.color.grayscale[200]};
                    &:hover {
                        background: ${theme.color.grayscale[700]};
                    }
                    &:disabled {
                        pointer-events: none;
                        background: ${theme.color.grayscale[700]};
                    }
                `
        }
    }}
`