import styled from "styled-components";

export const SwitchContainer = styled.div`
    display: inline-flex;
    align-items: center;
    height: 40px;
`

export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const StyledSwitch = styled.div<{ checked: boolean }>`
    cursor: pointer;
    text-indent: -9999px;
    width: 33px;
    height: 18px;
    background: ${({checked, theme}) => (checked ? theme.color.brand.yellow.regular: theme.color.grayscale[300])};
    display: block;
    border-radius: 100px;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        left: ${({checked}) => (checked ? "calc(55%)"  : "3px")};
        top: 3px;
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
        background: ${({theme}) => theme.color.grayscale[900]};
    }`;

export const Label = styled.label.attrs({
    className: 'text-lg'
})`
    margin-left: 16px;
    cursor: pointer;
`;