import styled from "styled-components";
import Input from "../../components/input/Input";

export const Wrapper = styled.div`
    max-width: 100%;
    width: 630px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
    
    
    h2 {
        text-align: center;
    }
    
    ul {
        list-style: disc;
        padding: 0;
    }
`

export const QuestionsWrapper = styled.div`
    border-radius: 16px;
    background-color: ${({theme}) => theme.color.grayscale[800]};
    overflow: auto;
    
    :last-child {
        border-bottom: none;
    }
`

export const SearchWrapper = styled.div`
    position: relative;
    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
    }
`

export const InputStyled = styled(Input)`
    height: 56px;
    width: 100%;
    padding-right: 48px;
`