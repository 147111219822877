import React from "react";
import {Wrapper} from "./Docs.style";
import {Outlet} from "react-router-dom";

const Docs: React.FC = () => {
    return <Wrapper>
        <Outlet/>
    </Wrapper>
}

export default Docs;