import { useLocation, useNavigate } from 'react-router-dom';

interface TQueryParam {
    checkQueryParams: () => void;
    setQueryParam: (param: string, value: string) => void;
}

export const usePersistQueryParams = (): TQueryParam => {
  const location = useLocation();
  const navigate = useNavigate();

  const checkQueryParams = () => {
    const queryParam = new URLSearchParams(location.search);
    const setParam = queryParam.has('ref');
    if (setParam) {
        const param = queryParam.get('ref');
        window.localStorage.setItem("param", param as string)
    }
  }

  const setQueryParam = (param: string, value: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(param, value);
    navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
  }

  return {
    checkQueryParams,
    setQueryParam
  }
};