import React from "react";

import {
    TableBodyStyled,
    TableCellStyled,
    TableFooterStyled,
    TableHeadCellStyled,
    TableHeadStyled,
    TableRowStyled,
    TableStyled
} from './Table.style'

interface Props {
    children: React.ReactNode;
}

const Table = ({children, ...props}: Props & React.ComponentProps<'table'>) => {
    return <TableStyled {...props}>
        {children}
    </TableStyled>
}

const TableHead: React.FC<Props | React.ComponentProps<'thead'>> = ({children, ...props}) => {
    return <TableHeadStyled {...props}>
        {children}
    </TableHeadStyled>
}

const TableBody: React.FC<Props | React.ComponentProps<'tbody'>> = ({children, ...props}) => {
    return <TableBodyStyled {...props}>
        {children}
    </TableBodyStyled>
}

const TableRow: React.FC<Props | React.ComponentProps<'tr'>> = ({children, ...props}) => {
    return <TableRowStyled {...props}>
        {children}
    </TableRowStyled>
}

const TableCell: React.FC<Props | React.ComponentProps<'td'>> = ({children, ...props}) => {
    return <TableCellStyled {...props}>
        {children}
    </TableCellStyled>
}

const TableHeadCell: React.FC<Props | React.ComponentProps<'th'>> = ({children, ...props}) => {
    return <TableHeadCellStyled {...props}>
        {children}
    </TableHeadCellStyled>
}

const TableFooter: React.FC<Props | React.ComponentProps<'tfoot'>> = ({children, ...props}) => {
    return <TableFooterStyled {...props}>
        {children}
    </TableFooterStyled>
}

Table.Row = TableRow;
Table.Cell = TableCell;
Table.Head = TableHead;
Table.Body = TableBody;
Table.HeadCell = TableHeadCell;
Table.Footer = TableFooter;
export default Table;