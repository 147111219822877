
const digest = 'c7bbc091f1227154742a5bd0d55a3df29eb913eb0ad4cafc7ab236b574002f65';
const classes = {"connectModal":"_connectModal_1kqtj_1","title":"_title_1kqtj_4","closeBtn":"_closeBtn_1kqtj_9","backBtn":"_backBtn_1kqtj_15","walletItem":"_walletItem_1kqtj_21","walletIcon":"_walletIcon_1kqtj_33","walletName":"_walletName_1kqtj_38","connecting":"_connecting_1kqtj_43","connectingIconContainer":"_connectingIconContainer_1kqtj_51","retryContainer":"_retryContainer_1kqtj_54","retryIcon":"_retryIcon_1kqtj_63","connectingIcon":"_connectingIcon_1kqtj_51","connection":"_connection_1kqtj_72","acceptRequest":"_acceptRequest_1kqtj_76","btnDownload":"_btnDownload_1kqtj_83"};
const css = `._connectModal_1kqtj_1{gap:12px}._connectModal_1kqtj_1 ._title_1kqtj_4{font-size:18px;font-weight:700;margin-bottom:4px}._connectModal_1kqtj_1 ._closeBtn_1kqtj_9{cursor:pointer;position:absolute;right:16px;top:16px}._connectModal_1kqtj_1 ._backBtn_1kqtj_15{cursor:pointer;left:16px;position:absolute;top:16px}._connectModal_1kqtj_1 ._walletItem_1kqtj_21{border:1px solid #3a3a3a;border-radius:8px;box-sizing:border-box;cursor:pointer;display:flex;padding:8px;width:100%}._connectModal_1kqtj_1 ._walletItem_1kqtj_21:hover{background:hsla(0,0%,100%,.05)}._connectModal_1kqtj_1 ._walletItem_1kqtj_21 ._walletIcon_1kqtj_33{border-radius:4px;height:42px;width:42px}._connectModal_1kqtj_1 ._walletItem_1kqtj_21 ._walletName_1kqtj_38{flex-grow:1;line-height:42px;text-align:center}._connectModal_1kqtj_1 ._connecting_1kqtj_43{align-items:center;display:flex;flex-direction:column;height:204px;justify-content:center;width:100%}._connectModal_1kqtj_1 ._connecting_1kqtj_43 ._connectingIconContainer_1kqtj_51{position:relative}._connectModal_1kqtj_1 ._connecting_1kqtj_43 ._connectingIconContainer_1kqtj_51 ._retryContainer_1kqtj_54{background:#4b5563;border-radius:50%;bottom:-12px;cursor:pointer;padding:4px;position:absolute;right:-12px}._connectModal_1kqtj_1 ._connecting_1kqtj_43 ._connectingIconContainer_1kqtj_51 ._retryContainer_1kqtj_54 ._retryIcon_1kqtj_63{height:24px;width:24px}._connectModal_1kqtj_1 ._connecting_1kqtj_43 ._connectingIcon_1kqtj_51{border-radius:4px;height:60px;width:60px}._connectModal_1kqtj_1 ._connecting_1kqtj_43 ._connection_1kqtj_72{font-size:18px;margin-top:20px}._connectModal_1kqtj_1 ._connecting_1kqtj_43 ._acceptRequest_1kqtj_76{color:hsla(0,0%,100%,.4);font-size:14px;margin-top:6px;text-align:center;white-space:pre-wrap}._connectModal_1kqtj_1 ._connecting_1kqtj_43 ._btnDownload_1kqtj_83{margin-top:20px}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  