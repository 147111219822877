import React from "react";
import {Outlet} from "react-router-dom";

import {Content, Navigation, NavigationItem, Wrapper} from "./Settings.style";

const Settings: React.FC = () => {
    return (
        <Wrapper>
            <Navigation>
                <NavigationItem to={'api-keys'}>API keys</NavigationItem>
                <NavigationItem to={'kyc-verification'}>KYC verification</NavigationItem>
                <NavigationItem to={'notifications'}>Manage Notifications</NavigationItem>
            </Navigation>
            <Content>
                <Outlet/>
            </Content>
        </Wrapper>
    )
}

export default Settings