import styled from "styled-components";

export const Wrapper = styled.div`
    width: 500px;
    max-width: 100%;
`;

export const Node = styled.div`
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    background-color: ${({theme}) => theme.color.grayscale[700]};
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    
`

export const TransactionHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background-color: ${({theme}) => theme.color.grayscale[700]};
    padding: 8px 16px;
    height: 40px;
    align-items: center;
`

export const PathWrapper = styled.div`
    padding: 0 16px 16px 16px;
    
    
`

export const Path = styled.div`
    width: 100%;
    height: 52px;
    border: 1px dashed ${({theme}) => theme.color.grayscale[400]};
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
`

export const NodesWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateY(50%);
    
    .arrow {
        //height: 20px;
        path {
            fill: ${({theme}) => theme.color.grayscale[400]};
        }
        transform: rotate(-90deg);
    }
`

export const ExchangedWrapper = styled.div.attrs({
    className: 'text-md'
})`
    border-radius: 8px;
    background-color: ${({theme}) => theme.color.grayscale[700]};
    padding: 8px 16px;
    width: fit-content;
    margin-top: 24px;
    
    .values {
        display: flex;
        align-items: center;
        gap: 8px;
        svg {
            transform: rotate(-90deg);
            path {
                fill: ${({theme}) => theme.color.grayscale[400]};
            }
            
        }
    }
`