import React, {useRef, useState} from "react";
import {Answer, Question, ContentWrapper, Wrapper} from "./FAQ.style";
import {ReactComponent as Arrow} from "assets/svgs/KeyboardArrowDown.svg";

interface Props {
    faq: {
        question: string,
        answer: string | JSX.Element
        opened: boolean
    },
    toggleFAQ: (index: number) => void,
    index: number
}

const FAQ: React.FC<Props> = ({faq,index, toggleFAQ}) => {

    const handleToggle = () => {
        toggleFAQ(index)
    }

    const contentRef = useRef<HTMLDivElement | null>(null);
    const contentHeight = faq.opened ? contentRef.current?.scrollHeight : 0;


    return <Wrapper>
        <Question $opened={faq.opened} onClick={handleToggle}>
            {faq.question}
            <div className={'arrow'}>
                <Arrow/>
            </div>

        </Question>
        <ContentWrapper $opened={faq.opened} maxHeight={contentHeight}>
            <Answer ref={contentRef} $opened={faq.opened}>
                {faq.answer}
            </Answer>
        </ContentWrapper>
    </Wrapper>
}

export default FAQ