import React from "react";

import {ReactComponent as SwapVertical} from "assets/svgs/SwapVertical.svg"

import {Wrapper} from "./SwapButton.style";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {

}

const SwapButton: React.FC<Props> = ({...props}) => {
    return <Wrapper {...props}>
        <SwapVertical/>
    </Wrapper>
}

export default SwapButton;