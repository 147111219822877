import styled from "styled-components";
import Input from "components/input/Input";

export const Wrapper = styled.div`
    padding: 16px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: ${({theme}) => theme.color.grayscale[900]};
    
    .crypto-select-container{
        max-height: 330px;
        width: 320px;
    }
    
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    
    p {
        color: ${({theme}) => theme.color.grayscale[300]};
    }
`

export const SelectTokenList = styled.div`
    overflow-y: auto;
    max-height: 200px;
    width: 290px;
    
    @media (max-width: ${({theme}) => theme.breakingPoints.sm}) {
        width: 240px;
    }
`

export const SearchTokenInput = styled(Input)`
    width: 100%;
`

export const SelectTokenWrapper =styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const TokenInput = styled.input`
    border: none;
    padding: 0;
    outline: none;
    text-align: right;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: ${({theme}) => theme.color.grayscale[100]};
    background-color: transparent;
    width: 100%;
    
    &::placeholder {
        color: ${({theme}) => theme.color.grayscale[100]};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    -moz-appearance: textfield;
`

export const MaxButton = styled.button.attrs({
    className: 'text-sm'
})`
    font-weight: 500;
    cursor: pointer;
    padding: 0 2px;
    background: rgba(241, 214, 101, 0.16);
    color: ${({theme}) => theme.color.brand.yellow.regular};
`
