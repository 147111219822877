import {createConfig, http} from '@wagmi/core'
import {btr, coreDao} from '@wagmi/core/chains'
import {metaMask, walletConnect} from "@wagmi/connectors";

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID

export const config = createConfig({
    chains: [btr,  coreDao],
    connectors: [
        metaMask(),
        walletConnect({projectId: projectId || ''}),
    ],
    transports: {
        [btr.id]: http(),
        [coreDao.id]: http(),
    },
    multiInjectedProviderDiscovery: false,
})