import { styled } from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;

    .code-button {
        width: 100%;
        max-width: 450px;
    }
`

export const CodeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
`