import styled, {css} from "styled-components";
import {ReactComponent as ExpandMore} from "assets/svgs/ExpandMore.svg";

export const Wrapper = styled.div.attrs({
    className: 'text-md'
})`
    display: inline-flex;
    gap: 24px;
    align-items: center;
    
    .pagination-info {
        display: inline-flex;
        gap: 24px;
        align-items: center;
    }
    
    .rows-per-page {
        display: flex;
        align-items: center;
        gap: 8px;
        
        .per-page-dropdown {
            cursor: pointer;
            display: flex;
            align-items: center;
            background-color: ${({theme}) => theme.color.grayscale[700]};
            border-radius: 4px;
            padding: 0 4px 0 8px;
        }
    }
    
    .page-select {
        display: flex;
        align-items: center;
        
        svg {
            cursor: pointer;
        }
    }
    
    @media screen and (max-width: ${({theme}) => theme.breakingPoints.sm}){
        font-size: 12px !important;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        width: 100%;

        .pagination-info {
            width: 100%;
            justify-content: space-between;
            gap: 8px;
        }
        
        .page-select {
            width: 100%;
            justify-content: space-between;
        }
    }
`

interface PageSelectProps {
    $disabled?: boolean
}

export const PageSelect = styled(ExpandMore)<PageSelectProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    ${({$disabled}) => $disabled && css`
        pointer-events: none;
        path {
            fill: ${({theme}) => theme.color.grayscale[400]};
        }
        opacity: 0.5;
    `}
    
`

export const PageSelectItem = styled.div<{$active?: boolean}>`
    padding: 12px 8px;
    color: ${({theme}) => theme.color.grayscale[300]};
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    
    &:hover {
        background-color: ${({theme}) => theme.color.grayscale[700]};
    }
    
    ${({$active, theme}) => $active && css`
        background-color: ${theme.color.brand.yellow.regular};
        color: ${theme.color.grayscale[900]};
        &:hover {
            background-color: ${theme.color.brand.yellow.regular};
        }
        pointer-events: none;
        
    `}
    
`