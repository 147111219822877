import React from "react";

import {Label, Row, Slippage, Value, Wrapper} from "./Summary.style";
import Skeleton from "react-loading-skeleton";
import {TSummary} from "../../../../../../types/types";
import {formatNumberToCurrency} from "../../../../../../utils";
import {useAppContext} from "../../../../../../hooks";

interface Props {
    summary: TSummary | undefined;
    summaryLoading: boolean;
    setTab: React.Dispatch<React.SetStateAction<"swap" | "limit" | "settings">>
}

const Summary: React.FC<Props> = ({summary, summaryLoading, setTab}) => {
    const {swapSlippage} = useAppContext()
    const handleOpenSettings = () => {
        setTab('settings');
    }

    return (
        <Wrapper>
            <Row>
                <Label>
                    {summaryLoading ? <Skeleton width={40} height={'100%'}/> : 'Fee'}

                </Label>
                <Value>
                    {summaryLoading ? <Skeleton width={40} height={'100%'}/> : <strong>~{formatNumberToCurrency(summary?.fee)}</strong>}
                </Value>
            </Row>
            <Row>
                <Label>
                    {summaryLoading ? <Skeleton width={56} height={'100%'}/> : 'Slippage'}
                </Label>
                <Slippage onClick={handleOpenSettings}>
                    {summaryLoading ? <Skeleton width={56} height={'100%'}/> : <><strong>{summary?.slippage}%</strong>{swapSlippage.selected === 0.5 ? <> &nbsp;&#183; Auto</> : null}</>}

                </Slippage>

            </Row>
            <Row>
                <Label>
                    {summaryLoading ? <Skeleton width={120} height={'100%'}/> : 'Minimum received'}

                </Label>
                <Value>
                    {summaryLoading ? <Skeleton width={120} height={'100%'}/> : <strong>~{summary?.minimumReceived?.toFixed(6)}</strong>}
                </Value>
            </Row>
            {/*<Dropdown*/}
            {/*    isOpen={isSlippageOpen}*/}
            {/*    setIsOpen={setIsSlippageOpen}*/}
            {/*    toggleElementRef={slippageRef}*/}
            {/*>*/}
            {/*    <SlippageSelectMenu>*/}
            {/*        <DropdownItem selected={false}>0.5% Auto</DropdownItem>*/}
            {/*        <DropdownItem selected={false}>1%</DropdownItem>*/}
            {/*        <DropdownItem selected={false}>5%</DropdownItem>*/}
            {/*        <Input placeholder="Custom" />*/}
            {/*        <Button variant={'text'}>Done</Button>*/}
            {/*    </SlippageSelectMenu>*/}
            {/*</Dropdown>*/}
        </Wrapper>
    );
}

export default Summary;