import React from "react";

const TermsOfService: React.FC = () => {
    return <>
        <h2>BITLiquidity Terms of Service</h2>
        <p><strong>Effective Date</strong>: 22/07/2024</p>
        <p>
            Welcome to BITLiquidity. These Terms of Service ("Terms") govern your access to and use of our website,
            platform, and services (collectively, the "Services"). By accessing or using our Services, you agree to be
            bound by these Terms. If you do not agree to these Terms, do not use our Services.
        </p>
        <h4>1. Acceptance of Terms</h4>
        <p>
            By accessing or using the Services, you affirm that you are at least 18 years old and have the legal
            capacity to enter into these Terms. If you are using the Services on behalf of an organization,
            you represent and warrant that you have the authority to bind that organization to these Terms.
        </p>
        <h4>2. Changes to Terms</h4>
        <p>
            We may modify these Terms at any time. We will notify you of any changes by posting the revised Terms on our
            website and updating the "Effective Date" above. Your continued use of the Services after the changes become
            effective constitutes your acceptance of the revised Terms.
        </p>
        <h4>3. Use of Services</h4>
        <p>3.1 <strong>Eligibility:</strong></p>
        <ul>
            <li> To use our Services, you must comply with all applicable laws and regulations. You are responsible for
                ensuring that your use of the Services is lawful and in compliance with these Terms.
            </li>
        </ul>
        <p>3.2 <strong>Account Registration:</strong></p>
        <ul>
            <li> You may need to create an account to access certain features of the Services. You agree to provide
                accurate
                and complete information during the registration process and to keep your account information
                up-to-date.
            </li>
        </ul>
        <p>3.3 <strong>Security:</strong></p>
        <ul>
            <li> You are responsible for maintaining the confidentiality of your account credentials and for all
                activities
                that occur under your account. Notify us immediately of any unauthorized use of your account.
            </li>
        </ul>
        <h4>4. Services</h4>
        <p>4.1 <strong>Description:</strong></p>
        <ul>
            <li> BITLiquidity provides a platform for aggregating transactions on the Bitcoin blockchain, focusing on
                BRC20 tokens and Ordinals. Our Services include access to liquidity pools, trading tools, and other
                related features.
            </li>
        </ul>
        <p>4.2 <strong>Modifications:</strong></p>
        <ul>
            <li> We may modify or discontinue the Services, in whole or in part, at any time without notice.
                We are not liable for any modification, suspension, or discontinuation of the Services.
            </li>
        </ul>
        <h4>5. Fees and Payments</h4>
        <p>5.1 <strong>Fees:</strong></p>
        <ul>
            <li> Certain features of the Services may require payment of fees. All fees are non-refundable unless
                otherwise stated.

            </li>
        </ul>
        <h4>6. User Conduct</h4>
        <p>You agree not to engage in any of the following prohibited activities:</p>
        <ul>
            <li>Using the Services for any unlawful purpose or in violation of any applicable laws.</li>
            <li>Interfering with or disrupting the Services or servers or networks connected to the Services.</li>
            <li>Attempting to access or search the Services by any means other than through our publicly supported
                interfaces.
            </li>
            <li>Impersonating any person or entity or misrepresenting your affiliation with any person or entity.</li>
        </ul>
        <h4>7. Intellectual Property</h4>
        <p>7.1 <strong>Ownership:</strong></p>
        <ul>
            <li> All content, trademarks, logos, and other intellectual property used in the Services are the property
                of BITLiquidity or its licensors.
            </li>
        </ul>
        <h4>8. Disclaimers and Limitation of Liability </h4>
        <p>8.1 <strong>Disclaimers:</strong></p>
        <ul>
            <li> The Services are provided "as is" and "as available" without any warranties of any kind, whether
                express or implied. We do not warrant that the Services will be uninterrupted, error-free, or secure.
            </li>
        </ul>
        <p>8.2 <strong>Limitation of Liability:</strong></p>
        <ul>
            <li> To the fullest extent permitted by law, BITLiquidity shall not be liable for any indirect, incidental,
                special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred
                directly
                or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your
                use
                or inability to use the Services; (ii) any unauthorized access to or use of our servers and/or any
                personal
                information stored therein; (iii) any interruption or cessation of transmission to or from the Services;
                (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Services by
                any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a
                result
                of the use of any content posted, emailed, transmitted, or otherwise made available through the
                Services;
                and/or (vi) the defamatory, offensive, or illegal conduct of any third party.
            </li>
        </ul>
        <h4>9. Indemnification</h4>
        <p>You agree to indemnify, defend, and hold harmless BITLiquidity, its affiliates, and their respective
            officers,
            directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses,
            including without limitation reasonable legal and accounting fees, arising out of or in any way connected
            with your access to or use of the Services or your violation of these Terms.</p>
        <h4>10. General Provisions</h4>
        <p>10.1 <strong>Disclaimers:</strong></p>
        <ul>
            <li> The Services are provided "as is" and "as available" without any warranties of any kind, whether
                express or implied. We do not warrant that the Services will be uninterrupted, error-free, or secure.
            </li>
        </ul>
        <p>10.2 <strong>Severability:</strong></p>
        <ul>
            <li> If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will
                remain in full force and effect.
            </li>
        </ul>
        <p>10.3 <strong>Waiver:</strong></p>
        <ul>
            <li> Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                rights.

            </li>
        </ul>
        <p>10.4 <strong>Contact Us:</strong></p>
        <ul>
            <li> If you have any questions about these Terms, please contact us at <a href="mailto:info@bitliquidity.io">info@bitliquidity.io</a>.
            </li>
        </ul>
    </>
}

export default TermsOfService;