import styled from "styled-components";

export const Content = styled.div`
    width: 750px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    max-width: 100%;
`

export const LoadingOverlay = styled.div`
    position: absolute;
    inset: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${({theme}) => theme.color.grayscale[800]};
    height: 100%;
`

export const SwapDetailsWrapper = styled.div`
    padding: 16px;
    border-radius: 8px;
    background-color: ${({theme}) => theme.color.grayscale[900]};
    width: 100%;
    
    .total-value{
        color: ${({theme}) => theme.color.brand.yellow.regular};
    }
`

export const DetailRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    border-bottom: 1px solid ${({theme}) => theme.color.grayscale[500]};
    padding: 16px 0;
    &:first-child {
        padding-top: 0;
    }
`

export const Label = styled.p`
    width: 200px;
    text-align: left;
`

export const Value = styled.p`
    flex: 1;
    text-align: right;
    
    span {
        font-size: 16px;
        color: ${({theme}) => theme.color.grayscale[300]};
    }
`

export const DollarValue = styled.p`
    flex: 1;
    text-align: right;
`

export const Footer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: end;
    align-items: center;
`