
const digest = '424d614dbc2763d7cad7ca35c00bd255c6ed55da308a1f3018594201a47127bf';
const classes = {"btn":"_btn_ofd87_1"};
const css = `._btn_ofd87_1{align-items:center;background:#fff;border-radius:8px;color:#000;display:flex;flex-direction:row;font-size:16px;gap:16px;justify-content:center;padding:6px 24px;position:relative}._btn_ofd87_1:hover{opacity:.85}._btn_ofd87_1:disabled{background:hsla(0,0%,100%,.2);color:hsla(0,0%,100%,.639);cursor:not-allowed;opacity:1}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  