import React from "react";
import {Content, Wrapper} from "./Alert.style";
import {ReactComponent as Success} from "assets/svgs/SuccessIcon.svg";
import {ReactComponent as Warning} from "assets/svgs/WarningIcon.svg";
import {ReactComponent as Error} from "assets/svgs/ErrorIcon.svg";
import {ReactComponent as Info} from "assets/svgs/InfoIcon.svg";
import {ReactComponent as CloseButton} from 'assets/svgs/CloseButton.svg';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    type: "success" | "error" | "warning" | "info";
    onClose?: () => void;
    children: React.ReactNode;
}

const Alert: React.FC<Props> = ({
    type,
    children,
    onClose,
    ...props
}) => {
    return <Wrapper $type={type} {...props}>
        <Content>
            {type === "success" && <Success />}
            {type === "warning" && <Warning />}
            {type === "error" && <Error />}
            {type === "info" && <Info />}
        {children}
        </Content>
        {onClose ? <CloseButton className={'close-button'} onClick={onClose} /> : null}
    </Wrapper>
}

export default Alert;