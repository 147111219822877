
const digest = '3ab1a455562ae7e24c2ac49572897e615b9e9275066fd0d64f0cf24844521bab';
const classes = {"modalContent":"_modalContent_aoysu_1","closeBtn":"_closeBtn_aoysu_5","signTitle":"_signTitle_aoysu_11","chainInfo":"_chainInfo_aoysu_16","addressContainer":"_addressContainer_aoysu_29","addressInfo":"_addressInfo_aoysu_34","detailsContent":"_detailsContent_aoysu_49","fill":"_fill_aoysu_58","unsignedMessage":"_unsignedMessage_aoysu_61","estimatedGas":"_estimatedGas_aoysu_76","signBtn":"_signBtn_aoysu_81","notRemind":"_notRemind_aoysu_85"};
const css = `._modalContent_aoysu_1{color:#fff;text-align:center}._modalContent_aoysu_1 ._closeBtn_aoysu_5{cursor:pointer;position:absolute;right:16px;top:16px}._modalContent_aoysu_1 ._signTitle_aoysu_11{font-size:20px;font-weight:600;margin-bottom:10px}._modalContent_aoysu_1 ._chainInfo_aoysu_16{align-items:center;display:flex;flex-direction:row;font-size:12px;gap:8px;justify-content:center;margin-bottom:8px}._modalContent_aoysu_1 ._chainInfo_aoysu_16 img{height:18px;width:18px}._modalContent_aoysu_1 ._addressContainer_aoysu_29{height:28px;margin-bottom:16px;margin-top:8px}._modalContent_aoysu_1 ._addressContainer_aoysu_29 ._addressInfo_aoysu_34{align-items:center;background:rgba(58,58,58,.635);border-radius:20px;display:flex;flex-direction:row;font-size:12px;gap:4px;justify-content:center;padding:4px 8px}._modalContent_aoysu_1 ._addressContainer_aoysu_29 ._addressInfo_aoysu_34 img{height:18px;width:18px}._modalContent_aoysu_1 ._detailsContent_aoysu_49{max-height:0;overflow-y:auto;transition:max-height .3s linear;width:100%}._modalContent_aoysu_1 ._detailsContent_aoysu_49::-webkit-scrollbar{display:none}._modalContent_aoysu_1 ._detailsContent_aoysu_49._fill_aoysu_58{max-height:500px}._modalContent_aoysu_1 ._detailsContent_aoysu_49 ._unsignedMessage_aoysu_61{background:#232529;border:1px solid #252525;border-radius:8px;box-sizing:border-box;color:#fff;font-size:14px;padding:8px;width:100%;word-wrap:break-word;color:hsla(0,0%,100%,.631);text-align:left;white-space:pre-wrap}._modalContent_aoysu_1 ._estimatedGas_aoysu_76{color:hsla(0,0%,100%,.631);font-size:12px;margin-top:24px}._modalContent_aoysu_1 ._signBtn_aoysu_81{margin-top:24px;min-width:200px}._modalContent_aoysu_1 ._notRemind_aoysu_85{align-items:center;color:hsla(0,0%,100%,.631);cursor:pointer;display:inline-flex;font-size:12px;gap:4px;margin-top:16px}._modalContent_aoysu_1 ._notRemind_aoysu_85:hover{opacity:.85}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  