import {useMutation, useQuery, keepPreviousData} from "@tanstack/react-query";
import TransactionsService from "../services/transactions.service";
import {TGetTransactionsParams} from "../types/types";


export const useGetTransactions = (params: TGetTransactionsParams) => {
    return useQuery({
        queryKey: ['getTransactions', params],
        queryFn: ({signal}) => TransactionsService.getTransactions(params, signal),
        enabled: !!params.tokenA && !!params.tokenB && (params.filter === 'mine' ? !!params.address : true),
        placeholderData: keepPreviousData

    })
}

export const useGetTransaction = (id: string) => {
    return useQuery({
        queryKey: ['getTransaction', id],
        queryFn: ({signal}) => TransactionsService.getTransaction(id, signal),
        enabled: !!id
    })
}

export const useUpdateTransaction = () => {
    return useMutation({
        mutationFn: TransactionsService.updateTransaction
    })
}

export const useAddTransaction = () => {
    return useMutation({
        mutationFn: TransactionsService.addTransaction
    })
}