import styled, {css} from "styled-components";
import Button from "components/button/Button";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 24px;
    background-color: ${({theme}) => theme.color.grayscale[800]};
    padding: 24px 16px;
    width: 520px;
    max-width: 100%;
    min-height: 502px;
    margin: auto;
    
    .swap-switch {
        background-color: ${({theme}) => theme.color.grayscale[900]};
        width: 100%;
        div {
            flex: 1;
            text-align: center;
        }
    }
    
    .settings-changed{
        path {
            fill: ${({theme}) => theme.color.brand.yellow.regular};
        }
    }
`

export const Title = styled.div`
    font-size: 1.25rem;
    line-height: ${({theme}) => theme.lineHeight.xs};
    font-weight: 700;
`

export const ConnectWalletButton = styled(Button).attrs({
    size: 'large',
    variant: 'primary'
})`
    width: 100%;
`

export const TokenWrapper = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    position: relative;
`

export const Content = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
`

export const Navigation = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
    svg {
        cursor: pointer;
        width: 28px;
        height: 28px;
    }
    
    .back-arrow {
        transform: rotate(90deg);
        margin-right: 24px;
    }
`

export const NavigationItem = styled.h4<{$active: boolean}>`
    cursor: pointer;
    display: flex;
    align-items: center;
    ${({$active, theme}) => !$active && css`
        color: ${theme.color.grayscale[400]};
    `}    

`