import React, {useCallback, useEffect, useMemo, useState} from "react";
import Table from "components/table/Table";
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import Pagination from "components/table/pagination/Pagination";
import {ReactComponent as Done} from "assets/svgs/CheckCircle.svg";
import {ReactComponent as Progress} from "assets/svgs/ProgressActivity.svg";
import {ReactComponent as Pending} from "assets/svgs/Pending.svg";
import {ReactComponent as Copy} from "assets/svgs/ContentCopyIcon.svg";
import {ReactComponent as VisitLink} from "assets/svgs/LinkIcon.svg";
import {useAppContext, usePagination} from "../../../../../hooks";
import {TTransaction} from "../../../../../types/types";
import { format } from 'date-fns';
import Alert from "../../../../../components/alert/Alert";
import {copyToClipboard, shortenAddress} from "../../../../../utils";
import {Address} from "viem";
import {Tooltip} from "react-tooltip";
import {
    MobileTableFooter,
    MobileTableItem,
    MobileTableWrapper,
    TransactionPathButton,
    StatusWrapper,
    TableWrapper
} from "../TransactionHistory.style";
import {BREAKING_POINTS} from "../../../../../consts/consts";
import MediaQuery from "react-responsive";

const columnHelper = createColumnHelper<TTransaction>()

const Status: React.FC<{status: string}> = ({status}) => {
    switch (status.toLowerCase()) {
        case 'pending':
            return <StatusWrapper status={'pending'}><Pending/>Pending</StatusWrapper>
        case 'running':
            return <StatusWrapper status={'running'} className={'running'}><Progress/>In Progress</StatusWrapper>
        case 'completed':
            return <StatusWrapper status={'completed'}><Done/>Completed</StatusWrapper>
        default:
            return <></>
    }
}

const TransactionTable: React.FC = () => {
    const {
        transactionHistoryData,
        transactionHistoryPagination,
        setTransactionHistoryPagination,
        setTransactionPathModalState,
        selectedChain
    } = useAppContext()
    const [tableData, setTableData] = useState<TTransaction[]>([])
    const pagination = usePagination(
        transactionHistoryPagination,
        setTransactionHistoryPagination
    )

    const [linkCopied, setLinkCopied] = useState(false)

    const scanUrl = selectedChain?.id === 1116  ? 'https://scan.coredao.org' : 'https://www.btrscan.com';

    useEffect(() => {
        setTableData(transactionHistoryData.data?.data.data || [])
    }, [transactionHistoryData])

    const handleOpenTransactionPath = useCallback((data: TTransaction) => {
        setTransactionPathModalState({
            isOpen: true,
            path: data.transactionRoute
        })
    }, [setTransactionPathModalState])

    const handleCopyTransactionHash = async (hash: string) => {
        await copyToClipboard(hash)
        setLinkCopied(true)
    }


    const columns = useMemo(() => [columnHelper.accessor('date', {
        header: 'Date',
        cell: props => format(new Date(props.getValue()), 'yyyy/MM/dd - hh:mm a'),

    }),
        columnHelper.accessor('state', {
            header: 'State',
            cell: props => {
                const value = props.getValue()
                return <Status status={value}/>
            },

        }),
        columnHelper.accessor('transactionHash', {
            header: 'Transaction hash',
            cell: props => <div className={'transaction-hash-column'}>
                <span>{shortenAddress(props.getValue() as Address, 16)}</span>
                <a data-tooltip-id={'tooltip'} data-tooltip-content={'Visit Link'} style={{display: 'flex'}} href={`${scanUrl}/tx/${props.getValue()}`} target={'_blank'} rel="noreferrer"><VisitLink/></a>
                <Copy id={'copy-transaction-hash'} onMouseLeave={() => {
                    setLinkCopied(false)
                }} onClick={() => handleCopyTransactionHash(props.getValue())}/>
            </div>,

        }),
        columnHelper.accessor('tokens', {
            header: 'Tokens',
            cell: props => props.getValue(),

        }),
        columnHelper.accessor('amount', {
            header: 'Amount',
            cell: props => props.getValue(),
        }),
        columnHelper.accessor('transactionRoute', {
            header: 'Action',
            cell: props => Object.keys(props.row.original.transactionRoute).length === 0 ? <></> :  <TransactionPathButton onClick={() => handleOpenTransactionPath(props.row.original)}>Details</TransactionPathButton>,
        }),], [handleOpenTransactionPath, scanUrl])

    const table = useReactTable({
        data: tableData,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })

    if(!tableData || tableData.length === 0) {
        return <Alert style={{margin: 'auto', width: 'fit-content'}} type={'info'}>Can't find any transactions</Alert>
    }

    return <><MediaQuery minWidth={BREAKING_POINTS.sm}>
        <TableWrapper>
        <Table>
            <Table.Head>
                <Table.Row>
                    {table.getHeaderGroups().map(headerGroup => {
                        return headerGroup.headers.map(header => {
                            return <Table.HeadCell key={header.id}>{flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}</Table.HeadCell>
                        })
                    })}
                </Table.Row>
            </Table.Head>

            <Table.Body>
                {table.getRowModel().rows.map(row => {
                    return <Table.Row key={row.id}>
                        {row.getVisibleCells().map(cell => {
                            return <Table.Cell key={cell.id}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </Table.Cell>
                        })}
                    </Table.Row>
                })}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.Cell className={'footer-pagination-row'} colSpan={6}>
                        <Pagination
                            pagination={pagination}
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
            <Tooltip anchorSelect="#copy-transaction-hash" className={'styled-tooltip'}>
                {linkCopied ? 'Copied!' : 'Copy'}
            </Tooltip>
        </Table>
        </TableWrapper>
    </MediaQuery>
        <MediaQuery maxWidth={BREAKING_POINTS.sm}>
            <MobileTableWrapper>
                {transactionHistoryData.data?.data.data.map((item, index) => {
                    return <MobileTableItem key={item.transactionHash}>
                        <div className={'row'}>
                            <div>{format(new Date(item.date), 'yyyy/MM/dd - hh:mm a')}</div>
                            <Status status={item.state}/>
                        </div>
                        <div>
                            <div>
                                {item.tokens}
                            </div>
                            <div className={'amount'}>
                                {item.amount}
                            </div>
                        </div>
                        <div className="row">
                                <span>{shortenAddress(item.transactionHash as Address, 12)}</span>
                                <a data-tooltip-id={'tooltip    '} data-tooltip-content={'Visit Link'}
                                   style={{display: 'flex'}} href={`https://www.btrscan.com/tx/${item.transactionHash}`}
                                   target={'_blank'} rel="noreferrer"><VisitLink/></a>
                                <Copy id={'copy-transaction-hash'} onMouseLeave={() => {
                                    setLinkCopied(false)
                                }} onClick={() => handleCopyTransactionHash(item.transactionHash)}/>

                        </div>
                        <TransactionPathButton onClick={() => handleOpenTransactionPath(item)}>Details</TransactionPathButton>
                    </MobileTableItem>
                })}
                <MobileTableFooter>
                    <Pagination
                        pagination={pagination}
                    />
                </MobileTableFooter>
            </MobileTableWrapper>
        </MediaQuery>
    </>
}

export default TransactionTable;