import React, {useCallback, useState} from "react";

import Button from "components/button/Button";

import {ReactComponent as Logo} from "assets/svgs/Logo.svg";

import {useAppContext, useAuthContext, useWallet} from "hooks";

import {Bun, Buns, Link, MobileNavigation, Navigation, TargetBurger, Wrapper} from "./Header.style";
import NetworkSelect from "features/network-select/NetworkSelect";
import WalletConnectedButton from "features/wallet-connected-button/WalletConnectedButton";
import MediaQuery from "react-responsive";
import {BREAKING_POINTS} from "../../consts/consts";


const Header: React.FC = () => {
    const {setIsConnectWalletModalOpened} = useAppContext();
    const [toggled, setToggled] = useState(false);

    const { isConnected } = useWallet()
    const { isAuthenticated, logout } = useAuthContext();

    const handleOpenConnectModal = useCallback(() => {
        setIsConnectWalletModalOpened(true)
    }, [setIsConnectWalletModalOpened])

    const handleMobileNavItemClick = useCallback(() => {
        setToggled(false)
    }, [])

    const handleMobileViewLogout = useCallback(() => {
        setToggled(false)
        logout()
    }, [])

    return <><Wrapper>
        <div className={'left-section'}>

            <Navigation>
                <Logo/>
                {!isAuthenticated && (
                    <ul className={'navigation-items'}>
                        <li>
                            <Link to={'swap'}>Swap</Link>
                        </li>
                        {/*<li>*/}
                        {/*    <Link to={'staking'}>Staking</Link>*/}
                        {/*</li>*/}
                        <li>
                            <Link to={'tasks'}>Beta Testing Vault</Link>
                        </li>
                        <li>
                            <Link to={'faq'}>FAQ</Link>
                        </li>
                    </ul>
                )}
                {!isAuthenticated && (
                    <TargetBurger onClick={() => setToggled(!toggled)}>
                        <Buns>
                            <Bun className={toggled ? 'toggled' : ''} />
                            <Bun className={toggled ? 'toggled' : ''} />
                            <Bun className={toggled ? 'toggled' : ''} />
                            <Bun className={toggled ? 'toggled' : ''} />
                        </Buns>
                    </TargetBurger>
                )}
            </Navigation>
        </div>
        <MediaQuery minWidth={BREAKING_POINTS.md}>
            <div  className={'right-section'}>
                {/* {isAuthenticated && <div style={{color: "#979797", cursor: "pointer"}} onClick={logout}>Logout</div>} */}
                <NetworkSelect/>
                {!isConnected ? <Button
                    variant={'primary'}
                    size={'small'}
                    onClick={handleOpenConnectModal}
                >
                    Connect Wallet
                </Button> : <WalletConnectedButton/>}
            </div>
        </MediaQuery>

    </Wrapper>
        <MediaQuery maxWidth={BREAKING_POINTS.md}>
            <MobileNavigation className={toggled ? 'open' : ''}>
                <ul className={toggled ? 'toggled' : ''}>
                    <li className={'mobile-nav-item'}>
                        <Link to={'swap'} onClick={handleMobileNavItemClick}>Swap</Link>
                    </li>
                    <li className={'mobile-nav-item'}>
                        <Link to={'tasks'} onClick={handleMobileNavItemClick}>Beta Testing Vault</Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={'staking'}>Staking</Link>*/}
                    {/*</li>*/}
                    <li className={'mobile-nav-item text-lg'}>
                        <Link to={'faq'} onClick={handleMobileNavItemClick}>FAQ</Link>
                    </li>
                    {/* <li className={'mobile-nav-item text-lg'}>
                        <div style={{color: "#979797"}} onClick={handleMobileViewLogout}>Logut</div>
                    </li> */}
                </ul>
            </MobileNavigation>
        </MediaQuery>
    </>
}

export default Header;