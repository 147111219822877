import React, {createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import AuthProvider from './AuthProvider';
import {Chain} from "viem";
import {useAccount, useChainId, useChains} from "wagmi";
import {
    TGetTransactionsParams, TPagination,
    TToken,
    TTokenPriceResponse,
    TTradingInterface,
    TTransaction, TTransactionPathModalState, TWithPagination
} from "../types/types";
import {useGetPriceForSelectedTokens, useGetTokensForSelectedChainService, useGetTransactions} from "queries";
import {AxiosResponse} from "axios";
import {UseQueryResult} from "@tanstack/react-query";
import {useETHProvider} from "@particle-network/btc-connectkit";

export interface AppContextValue {
    isConnectWalletModalOpened: boolean;
    setIsConnectWalletModalOpened:  React.Dispatch<React.SetStateAction<boolean>>;
    swapSlippage: {selected: number | string, value: number};
    setSwapSlippage: React.Dispatch<React.SetStateAction<{selected: number | string, value: number}>>;
    transactionDuration: string;
    setTransactionDuration: React.Dispatch<React.SetStateAction<string>>;
    swapType: string;
    setSwapType: React.Dispatch<React.SetStateAction<string>>;
    selectedChain: Chain | undefined;
    swapTokens: () => void;
    youGiveToken: TToken | undefined;
    setYouGiveToken: React.Dispatch<React.SetStateAction<TToken | undefined>>;
    youGetToken: TToken | undefined;
    setYouGetToken: React.Dispatch<React.SetStateAction<TToken | undefined>>;
    tokens: TToken[];
    tokensDetails: TTokenPriceResponse | undefined;
    tokensLoading: boolean;
    tokensDetailsLoading: boolean;
    youGiveAmount: string;
    setYouGiveAmount: React.Dispatch<React.SetStateAction<string>>;
    youGetAmount: string;
    setYouGetAmount: React.Dispatch<React.SetStateAction<string>>;
    slippageValue: string;
    setSlippageValue: React.Dispatch<React.SetStateAction<string>>;
    tradingInterface: TTradingInterface;
    setTradingInterface: React.Dispatch<React.SetStateAction<TTradingInterface>>;
    isConfirmSwapModalOpened: boolean;
    setIsConfirmSwapModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
    transactionHistoryData:  UseQueryResult<AxiosResponse<TWithPagination<TTransaction>, any>, Error>;
    transactionHistoryFilter: 'mine' | 'all';
    setTransactionHistoryFilter: React.Dispatch<React.SetStateAction<'mine' | 'all'>>;
    transactionHistoryPagination: TPagination;
    setTransactionHistoryPagination: React.Dispatch<React.SetStateAction<TPagination>>;
    transactionPathModalState: TTransactionPathModalState;
    setTransactionPathModalState: React.Dispatch<React.SetStateAction<TTransactionPathModalState>>;
    transactionHistoryParams: TGetTransactionsParams;
}

export const AppContext = createContext<AppContextValue | undefined>(undefined);

interface AppProviderProps {
    children: ReactNode;
}


const AppProvider: FC<AppProviderProps> = ({ children }) => {
    const [isConnectWalletModalOpened, setIsConnectWalletModalOpened] = useState<boolean>(false);
    const [isConfirmSwapModalOpened, setIsConfirmSwapModalOpened] = useState<boolean>(false);
    const [transactionPathModalState, setTransactionPathModalState] = useState<TTransactionPathModalState>({
        isOpen: false,
        path: null
    });
    const [swapSlippage, setSwapSlippage] = useState<{selected: number | string, value: number}>({
        selected: 0.5,
        value: 0.5
    });
    const [slippageValue, setSlippageValue] = useState<string>('0.5');

    const [transactionDuration, setTransactionDuration] = useState<string>('');
    const [swapType, setSwapType] = useState<string>('brc_20');

    const [tradingInterface, setTradingInterface] = useState<TTradingInterface>('simple');

    const [youGiveToken, setYouGiveToken] = useState<TToken | undefined>();
    const [youGetToken, setYouGetToken] = useState<TToken | undefined>();
    const [youGiveAmount, setYouGiveAmount] = useState<string>('1');
    const [youGetAmount, setYouGetAmount] = useState<string>('0');

    const [transactionHistoryFilter, setTransactionHistoryFilter] = useState<'mine' | 'all'>('mine')
    const [transactionHistoryPagination, setTransactionHistoryPagination] = useState<TPagination>({
        skip: 0,
        limit: 10,
        totalItems: 0
    })

    // Set default chain
    const chains = useChains();
    const chainId = useChainId();
    const { isConnected, address } = useAccount()
    const {account} = useETHProvider()

    const walletAddress = useMemo(() => isConnected ? address : account ? account : undefined , [account, address, isConnected])
    const isWalletConnected = useMemo(() => isConnected || !!account , [account, isConnected])

    const selectedChain = useMemo(() => {
        return chains.find((chain) => chain.id === chainId)
    }, [chainId, chains])

    useEffect(() => {
        setTransactionHistoryPagination((prev) => ({...prev, skip: 0}))
    }, [youGetToken, youGiveToken])

    const swapTokens = useCallback(() => {
        if (youGiveToken && youGetToken) {
            setYouGiveToken(youGetToken);
            setYouGiveAmount(youGetAmount)
            setYouGetToken(youGiveToken);
        }
    }, [youGiveToken, youGetToken, youGetAmount])

    const getTokensQuery = useGetTokensForSelectedChainService(chainId)
    const getTokensPriceQuery = useGetPriceForSelectedTokens({
        amountTokenA: parseFloat(youGiveAmount),
        tokenB: youGetToken?.id,
        tokenA: youGiveToken?.id,
        slippage: Number(slippageValue),
        userAddress: walletAddress
    })

    const tokens = useMemo(() => {
        return getTokensQuery.data?.data ?? [];
    }, [getTokensQuery.data?.data])

    const tokensDetails = useMemo(() => {
        return getTokensPriceQuery.data?.data;
    }, [getTokensPriceQuery.data?.data])

    useEffect(() => {
        setYouGetAmount((tokensDetails && youGetToken) ? tokensDetails[youGetToken?.id]?.amount?.toFixed(6).toString() : '')
    }, [tokensDetails, youGetToken, youGiveToken])

    useEffect(() => {
        if(tokens.length > 1){
            setYouGiveToken(tokens[0])
            setYouGetToken(tokens[1])
        }
    }, [tokens])

    //TRANSACTION HISTORY
    const transactionHistoryParams = useMemo<TGetTransactionsParams>(() => (
        {
            tokenA: youGiveToken?.id,
            tokenB: youGetToken?.id,
            address: walletAddress,
            filter: transactionHistoryFilter,
            skip: transactionHistoryPagination.skip,
            limit: transactionHistoryPagination.limit,
        }
    ), [
        youGiveToken?.id,
        youGetToken?.id,
        walletAddress,
        transactionHistoryFilter,
        transactionHistoryPagination.skip,
        transactionHistoryPagination.limit
    ])

    const transactionHistoryData = useGetTransactions(transactionHistoryParams)

    useEffect(() => {
        if(isWalletConnected) {
            setTransactionHistoryFilter('mine')
        } else {
            setTransactionHistoryFilter('all')
        }
    }, [isWalletConnected]);

    useEffect(() => {
        if(transactionHistoryData.data) {
            setTransactionHistoryPagination((prev) => (
                {
                    ...prev,
                    totalItems: transactionHistoryData.data.data.totalItems
                }
            ))
        }
    }, [transactionHistoryData.data]);


    const contextValue = useMemo(() => ({
        isConnectWalletModalOpened,
        setIsConnectWalletModalOpened,
        swapSlippage,
        setSwapSlippage,
        transactionDuration,
        setTransactionDuration,
        swapType,
        setSwapType,
        selectedChain,
        swapTokens,
        youGiveToken,
        setYouGiveToken,
        youGetToken,
        setYouGetToken,
        tokens,
        tokensDetails,
        tokensLoading: getTokensQuery.isLoading,
        tokensDetailsLoading: getTokensPriceQuery.isLoading,
        youGiveAmount,
        setYouGiveAmount,
        youGetAmount,
        setYouGetAmount,
        slippageValue,
        setSlippageValue,
        tradingInterface,
        setTradingInterface,
        isConfirmSwapModalOpened,
        setIsConfirmSwapModalOpened,
        transactionHistoryData,
        transactionHistoryFilter,
        setTransactionHistoryFilter,
        transactionHistoryPagination,
        setTransactionHistoryPagination,
        transactionPathModalState,
        setTransactionPathModalState,
        transactionHistoryParams
    }), [
        isConnectWalletModalOpened,
        swapSlippage,
        transactionDuration,
        swapType,
        selectedChain,
        swapTokens,
        youGiveToken,
        youGetToken,
        tokens,
        tokensDetails,
        getTokensQuery.isLoading,
        getTokensPriceQuery.isLoading,
        youGiveAmount,
        youGetAmount,
        slippageValue,
        tradingInterface,
        isConfirmSwapModalOpened,
        transactionHistoryData,
        transactionHistoryFilter,
        transactionHistoryPagination,
        transactionPathModalState,
        transactionHistoryParams
    ]);

    return (
        <AuthProvider>
            <AppContext.Provider value={contextValue}>
                {children}
            </AppContext.Provider>
        </AuthProvider>
    );
};


export default AppProvider;
