import React from "react";

import {useAuthContext} from "hooks";
import { Navigate } from "react-router-dom";

interface Props {
    children: React.ReactNode;
}

export const ProtectedRoute: React.FC<Props> = ({children}) => {
    const { isAuthenticated } = useAuthContext();

    // if (!isAuthenticated) {
    //     return <Navigate to="/auth/login" />;
    // }

    return children as React.ReactElement
};