import Input  from "components/input/Input";
import Form from "components/form/Form";

import { useState } from "react"
import { useCode } from "./hooks/useCode";
import Button from "components/button/Button";

import { Wrapper, CodeWrapper } from "./code.style";
import { Link } from "react-router-dom";
import { useAuthContext } from "hooks";

const Code = () => {
    const {getTaskCode} = useCode();
    const { isAuthenticated } = useAuthContext();

    const [showCode, setShowCode] = useState(false);
    const [code, setCode] = useState("");
    const [inputValue, setInputValue] = useState("");

    const inputOnChange = (event: any) => {
        setInputValue(event.target.value)
    }

    const handleGetTaskCode = async() => {
        try {
            const responseData = await getTaskCode(inputValue);
            if(responseData?.status === 200) {
                setCode(responseData.data.code);
                setShowCode(true);
                setInputValue("");
            }
        } catch(error) {
        }
    }

    return (
        <div>
            {!showCode && (
                <Wrapper>
                    <Form.Row>
                        <Input label="Telegram handle" placeholder="Enter your telegram handler" onChange={inputOnChange} value={inputValue} />
                    </Form.Row>
                    <Button onClick={handleGetTaskCode} className="code-button">Get code</Button>
                </Wrapper>
            )} 
            {showCode && (
                <CodeWrapper>
                    <div>Please write down the code as it will be used to unlock additional tasks and earn additional rewards.</div>
                    <div>{code}</div>
                    {/* <Form.Row> */}
                    {!isAuthenticated && (
                        <Form.Description>
                            Please login to proceed further <Link to={'/auth/login'}>Sign in</Link>
                        </Form.Description>
                    )}
                    {/* </Form.Row> */}
                </CodeWrapper>
            )}
        </div>
    )
}

export default Code