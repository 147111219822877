import React from "react";
import {createPortal} from "react-dom";
import {Content, Header, ModalContainer, Overlay} from './Modal.style';
import {ReactComponent as CloseButton} from 'assets/svgs/CloseButton.svg';

interface Props {
    opened: boolean;
    title: string | React.ReactNode;
    closeModal: () => void;
    children: React.ReactNode;
}

const Modal: React.FC<Props> = ({opened, title, closeModal, children}) => {

    const element = document.getElementById('modal');

    if(!opened || !element) {
        return null;
    }

    return createPortal(
        <Overlay>
            <ModalContainer>
                <Header>
                    {title}
                    <CloseButton style={{cursor: 'pointer'}} onClick={closeModal}/>
                </Header>
                <Content>
                    {children}
                </Content>
            </ModalContainer>
        </Overlay>, element
    )
}

export default Modal;