import {Connector, useAccount, useChainId, useConnect, useDisconnect} from "wagmi";
import {BaseConnector, useAccounts, useConnector, useConnectModal, useETHProvider} from "@particle-network/btc-connectkit";
import {useWalletService} from "../queries";
import {toast} from "react-toastify";
import {useMemo, useState} from "react";
import {useAppContext} from "./useAppContext";

export const useWallet = () => {
    const [connectPending, setConnectPending] = useState(false)

    const {setIsConnectWalletModalOpened} = useAppContext();
    const { isConnected, address } = useAccount()
    // const {accounts} = useAccounts()

    // console.log('accounts', accounts)

    const chainId = useChainId()
    const updateConnectedWallet = useWalletService()
    const {account, provider} = useETHProvider()

    // console.log('account', account)

    const {disconnect: particleDisconnect} = useConnectModal()


    const {disconnect} = useDisconnect()
    // const {disconnect: disconnectParticle} = useConnectModal()

    // console.log('accounts', accounts)

    const handleDisconnect = () => {
        disconnect()
        if(particleDisconnect) {
            particleDisconnect()
        }
    }

    const {connectors, connectAsync} = useConnect()


    const {connectors: particleConnectors, connect} = useConnector()


    const handleConnectWagmi = async (connector: Connector) => {
        try {
            if (connector) {
                const data = await connectAsync({connector, chainId: chainId})
                updateConnectedWallet.mutate({
                        address: data.accounts[0],
                        chain: String(chainId)
                    }
                )
            }

            toast('Wallet connected successfully', {type: 'success'})
        } catch (e) {
            toast('Failed to connect wallet', {type: 'error'})
        } finally {
            setIsConnectWalletModalOpened(false);
        }
    }

    const handleConnectParticleNetwork = async (connector: BaseConnector) => {
        try {
            if (connector) {
                const data = await connect(connector.metadata.id)
                updateConnectedWallet.mutate({
                        address: data[0],
                        chain: String(chainId)
                    }
                )
            }

            toast('Wallet connected successfully', {type: 'success'})
        } catch (e) {
            //@ts-ignore
            if(e.message && e.message.includes('is not install')){
                window.open(connector.metadata.downloadUrl, '_blank')
            }
            toast('Failed to connect wallet', {type: 'error'})
        } finally {
            setIsConnectWalletModalOpened(false);
        }
    }

    const handleConnect = async (connector: Connector | BaseConnector) => {
        setConnectPending(true)
        if(connector instanceof BaseConnector) {
            await handleConnectParticleNetwork(connector)
        } else {
            await handleConnectWagmi(connector)
        }
        setConnectPending(false)
    }



    const walletName: string = useMemo(() => {
        const wagmiStore = localStorage.getItem('wagmi.store') ? JSON.parse(localStorage.getItem('wagmi.store') as string) : null
        const particleConnectorId = localStorage.getItem('current-connector-id')
        if(wagmiStore){
            if(wagmiStore.state?.connections?.value){
                const value = wagmiStore.state?.connections?.value[1]
                if(value) {
                    return value.connector?.name
                }
            }
        }

        if(particleConnectorId){
            return particleConnectors.find(connector => connector.metadata.id === particleConnectorId)?.metadata.name
        }

        return ''
    }, [particleConnectors])

    return {
        isConnected: isConnected || account,
        connect: handleConnect,
        connectors: particleConnectors ? [...connectors, ...particleConnectors] : connectors,
        isPending: connectPending,
        address: isConnected ? address : account ? account : undefined,
        disconnect: handleDisconnect,
        walletName
    }
}