import React, {useCallback, useState} from "react";
import {BottomSectionWrapper, FooterLink, MobileFooterButtonGroup, TopSectionWrapper, Wrapper} from "./Footer.style";
import NetworkSelect from "../../features/network-select/NetworkSelect";
import Button from "../../components/button/Button";
import WalletConnectedButton from "../../features/wallet-connected-button/WalletConnectedButton";
import {useAppContext, useWallet} from "../../hooks";
import {ReactComponent as Logo} from "assets/svgs/Logo.svg";
import {ReactComponent as Telegram} from "assets/svgs/TelegramIcon.svg";
import {ReactComponent as Twitter} from "assets/svgs/TwitterIcon.svg";
import {ReactComponent as Linktree} from "assets/svgs/LinktreeIcon.svg";
import MediaQuery from "react-responsive";
import {BREAKING_POINTS} from "../../consts/consts";

const Footer: React.FC = () => {
    const {setIsConnectWalletModalOpened} = useAppContext();

    const { isConnected } = useWallet()

    const handleOpenConnectModal = useCallback(() => {
        setIsConnectWalletModalOpened(true)
    }, [setIsConnectWalletModalOpened])
    return <Wrapper>
        <TopSectionWrapper>
            <div className={'column socials'}>
                <Logo className={'logs'}/>
                <div className={'socials-icons'}>
                    <a href={'https://t.me/BITLiquidity_official'} target={'_blank'}><Telegram className={'logs'}/></a>
                    <a href={'https://x.com/BITLiquidityBTC'} target={'_blank'}><Twitter className={'logs'}/></a>
                    <a href={'https://linktr.ee/bitliquidity'} target={'_blank'}><Linktree className={'logs'}/></a>
                </div>
            </div>
            <div className={'column'}>
                <p className={'label'}>Contact</p>
                <a className={'mail-link'} href="mailto:info@bitliquidity.io">info@bitliquidity.com</a>
            </div>
            <div className={'column'}>
                <FooterLink to={'swap'}>Swap</FooterLink>
                <FooterLink to={'tasks'}>Beta Testing Vault</FooterLink>
                <FooterLink to={'faq'}>FAQ</FooterLink>
            </div>
            <div className={'column'}>
                <FooterLink to={'docs'} style={{pointerEvents: 'none'}}>Docs</FooterLink>
                <FooterLink to={'about'} style={{pointerEvents: 'none'}}>About</FooterLink>
                <FooterLink to={'https://drive.google.com/drive/folders/1fSSMxjc-TT-vD6Efah4u8SQb8Qs7XbSu'} target={'_blank'}>Brand kit</FooterLink>
            </div>
        </TopSectionWrapper>
        <BottomSectionWrapper>
            <p className={'copyright'}>
                &#169; 2024 BITLiquidity
            </p>
            <div className={'links'}>
                <FooterLink to={'/docs/privacy-policy'}>Privacy Policy</FooterLink>
                <FooterLink to={'/docs/terms-of-service'}>Term of Service</FooterLink>
                <a href="/" target={'_blank'} style={{pointerEvents: 'none'}}>Cookie settings</a>
            </div>

        </BottomSectionWrapper>
        <MediaQuery maxWidth={BREAKING_POINTS.md}>
            <MobileFooterButtonGroup>
                <NetworkSelect
                    toggleButtonClassName={'network-select-toggle'}
                    containerClassName={'network-select-container'}
                    itemClassName={'network-select-item'}
                />
                {!isConnected ? <Button
                    style={{flex: 1}}
                    variant={'primary'}
                    size={'small'}
                    onClick={handleOpenConnectModal}
                >
                    Connect Wallet
                </Button> : <WalletConnectedButton/>}
            </MobileFooterButtonGroup>
        </MediaQuery>

    </Wrapper>
}

export default Footer;