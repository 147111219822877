import React from "react";
import {
    Title,
    Wrapper
} from "./TransactionHistory.style";
import TransactionTable from "./components/Table";
import OptionSwitch from "components/option-switch/OptionSwitch";
import {TRANSACTION_HISTORY_OPTIONS} from "consts/consts";
import {useAppContext, useWallet} from "../../../../hooks";
import Alert from "../../../../components/alert/Alert";

const TransactionHistory: React.FC = () => {
    const {
        transactionHistoryFilter,
        setTransactionHistoryFilter,
        setIsConnectWalletModalOpened,
        setTransactionHistoryPagination,
    } = useAppContext()
    const handleOptionChange = (value: string | number) => {
        setTransactionHistoryFilter(value as 'mine' | 'all')
        setTransactionHistoryPagination((prev) => ({...prev, skip: 0}))
    }

    const {isConnected} = useWallet()


    const handleOpenConnectWalletModal = () => {
        setIsConnectWalletModalOpened(true)
    }

    return <Wrapper>
        <Title>
            Transaction History
        </Title>
        <OptionSwitch
            options={TRANSACTION_HISTORY_OPTIONS}
            onOptionChange={handleOptionChange}
            activeOption={transactionHistoryFilter}
            optionClassName={'transaction-history-option-item'}
            className={'transaction-history-option-switch-wrapper'}
            style={{marginBottom: '16px'}}
        />
        {(!isConnected && transactionHistoryFilter === 'mine') ?
            <Alert style={{margin: 'auto', width: 'fit-content', cursor: 'pointer'}} type={'info'} onClick={handleOpenConnectWalletModal}>Please connect wallet.</Alert> :
            <TransactionTable/>
        }

    </Wrapper>
}

export default TransactionHistory;