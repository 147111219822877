
const digest = '906d42389ecad5c2ab92642c8e0775ea667c413b069684ce9d01199562f2d0be';
const classes = {"loader":"_loader_g0am3_1","rotate":"_rotate_g0am3_1","prixClipFix":"_prixClipFix_g0am3_1"};
const css = `._loader_g0am3_1{animation:_rotate_g0am3_1 1s linear infinite;border-radius:50%;height:20px;position:relative;width:20px}._loader_g0am3_1:before{animation:_prixClipFix_g0am3_1 2s linear infinite;border:2px solid #000;border-radius:50%;box-sizing:border-box;content:"";inset:0;position:absolute}@keyframes _rotate_g0am3_1{to{transform:rotate(1turn)}}@keyframes _prixClipFix_g0am3_1{0%{clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}25%{clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}50%{clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}75%{clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}to{clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  