import styled from "styled-components";

export const Wrapper = styled.header`
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`

export const Content = styled.div`
    width: 636px;
    max-width: 100%;
    padding: 40px;
    border-radius: 24px;
    background-color: ${({theme}) => theme.color.grayscale[800]};
`