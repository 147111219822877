import React from "react";
import {Chain} from "viem";

import {ReactComponent as BitcoinIcon} from "assets/svgs/BitcoinNetworkIcon.svg";
import {ReactComponent as BitlayerIcon} from "assets/svgs/BitlayerNetworkIcon.svg";
import {ReactComponent as CoreIcon} from "assets/svgs/CoreNetworkIcon.svg"

interface Props extends React.SVGProps<SVGSVGElement> {
    chain: Chain | undefined
}

const ChainIcon: React.FC<Props> = ({chain, ...rest}) => {
    switch (chain?.name.toLowerCase()) {
        case 'btc':
            return <BitcoinIcon {...rest}/>
        case 'bitlayer':
            return <BitlayerIcon {...rest}/>

        // case 'rootstock':
        //     return {
        //         ...selectedNetwork,
        //         icon: <RootstockIcon/>
        //     }
        case 'core dao':
            return <CoreIcon {...rest}/>

        default:
            return  <BitcoinIcon {...rest}/>

    }
}

export default ChainIcon;
