import React from "react";
import {SwapSection, TransactionsSection, Wrapper} from "./SwapPage.style";
import Swap from "./features/swap/Swap";
import TransactionHistory from "./features/transaction-history/TransactionHistory";


const SwapPage: React.FC = () => {
    return <Wrapper>
        <SwapSection>
            {/*    <Chart/>*/}
            <Swap/>
        </SwapSection>
        <TransactionsSection>
            <TransactionHistory/>
        </TransactionsSection>

    </Wrapper>
}

export default SwapPage;