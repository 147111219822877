import React from "react";
import {useDisconnect, useConnections} from "wagmi";
import {Wrapper} from "./WalletConnectedButton.style";
import {shortenAddress} from "utils";
import Dropdown, {DropdownItem} from "components/dropdown/Dropdown";
import {ReactComponent as MetamaskIcon} from "assets/svgs/MetaMaskIcon.svg";
import {toast} from "react-toastify";
import {useWallet} from "../../hooks";
import {Address} from "viem";
import WalletIcon from "../../components/wallet-icon/WalletIcon";

const WalletConnectedButton: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const {address, disconnect, walletName} = useWallet()


    const toggleRef = React.useRef<HTMLDivElement>(null)

    const handleDisconnect = () => {
        disconnect()
        setIsOpen(false)
        toast('Wallet disconnected successfully', {type: 'success'})
    }

    return <>
        <Dropdown isOpen={isOpen} setIsOpen={setIsOpen} toggleElementRef={toggleRef}>
            <DropdownItem selected={false} onClick={handleDisconnect}>Disconnect Wallet</DropdownItem>
        </Dropdown>
        <Wrapper ref={toggleRef}>
            <WalletIcon name={walletName}/>
            <div className={'account-address'}>{shortenAddress(address as Address)}</div>
        </Wrapper>

    </>
}

export default WalletConnectedButton;