import React from "react";
import Modal from "../../components/modal/Modal";
import {useAppContext} from "../../hooks";
import {
    Content,
    DetailRow,
    SwapDetailsWrapper,
    Label,
    Value,
    DollarValue,
    Footer,
    LoadingOverlay
} from "./ConfirmSwap.style";
import Button from "../../components/button/Button";
import {formatNumberToCurrency} from "../../utils";
import {useEtherumContext} from "../../hooks/useEtherumContext";
import Spinner from "../../components/spinner/Spinner";

const ConfirmSwap: React.FC = () => {
    const {
        isConfirmSwapModalOpened,
        setIsConfirmSwapModalOpened,
        tokensDetails,
        youGiveToken,
        slippageValue
    } = useAppContext();

    const {aggregate, approveYouGiveToken, allowancePassed, aggregateLoading, approveLoading, setAllawencePassed} = useEtherumContext()

    const details = (tokensDetails && youGiveToken) ? tokensDetails[youGiveToken?.id] : null

    const handleClose = () => {
        setIsConfirmSwapModalOpened(false);
        setAllawencePassed(false);
    }

    return <Modal opened={isConfirmSwapModalOpened} closeModal={handleClose} title={'Confirm token swap'}>
        <Content>
            {(approveLoading || aggregateLoading) &&
                <LoadingOverlay>
                    <div>
                        <Spinner size={80}/>
                        <p style={{marginTop: '8px'}}>
                            Please Wait...
                        </p>
                    </div>
                </LoadingOverlay>
            }

            <p className={'text-lg'}>Please confirm the transaction below:</p>
            <SwapDetailsWrapper>
                <DetailRow>
                    <Label>Total value:</Label>
                    <Value>{details?.amount} <span>{youGiveToken?.ticker}</span></Value>
                    <DollarValue>~{formatNumberToCurrency(details?.price)}</DollarValue>
                </DetailRow>
                <DetailRow>
                    <Label>Service fee:</Label>
                    <Value>{tokensDetails?.serviceFee.amount} <span>sats</span></Value>
                    <DollarValue>~{formatNumberToCurrency(tokensDetails?.serviceFee.amount)}</DollarValue>
                </DetailRow>
                <DetailRow>
                    <Label>Transaction fee rate:</Label>
                    <Value>{tokensDetails?.transactionFee.amount} <span>sats</span></Value>
                    <DollarValue>~{formatNumberToCurrency(tokensDetails?.transactionFee.usdValue ?? undefined)}</DollarValue>
                </DetailRow>
                <DetailRow style={{borderBottom: 'none', paddingBottom: 0}}>
                    <Label>Total:</Label>
                    <Value className={'total-value'}>{tokensDetails?.total?.amount?.toFixed(6)} <span>{youGiveToken?.ticker}</span></Value>
                    <DollarValue
                        className={'total-value'}>~{tokensDetails?.total.usdValue ? formatNumberToCurrency(tokensDetails?.total.usdValue) : 'N/A'}</DollarValue>
                </DetailRow>
                <DetailRow style={{borderBottom: 'none', paddingBottom: 0}}>
                    <Label>Available balance:</Label>
                    <Value>{details?.balance} <span>{youGiveToken?.ticker}</span></Value>
                    <DollarValue></DollarValue>
                </DetailRow>
            </SwapDetailsWrapper>
            <Footer>
                <Button variant={'text'} onClick={handleClose}>Cancel</Button>
                {((youGiveToken && tokensDetails) && tokensDetails[youGiveToken?.id]?.allowance >= tokensDetails[youGiveToken?.id]?.amount) || allowancePassed ?
                    <Button variant={'primary'} onClick={aggregate}>Confirm</Button> :
                    <Button variant={'primary'} onClick={approveYouGiveToken}>Approve</Button>
                }

            </Footer>
        </Content>
    </Modal>
}

export default ConfirmSwap;