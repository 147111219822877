import React from "react";
import Modal from "components/modal/Modal";
import {useAppContext} from "../../hooks";
import {
    Path,
    PathWrapper,
    TransactionHeader,
    Wrapper,
    Node,
    NodesWrapper,
    ExchangedWrapper
} from "./TransactionPath.style";
import {ReactComponent as KeyboardArrowDown} from "assets/svgs/KeyboardArrowDown.svg";

const TransactionPath: React.FC = () => {
    const {transactionPathModalState, setTransactionPathModalState} = useAppContext()

    const handleClose = () => {
        setTransactionPathModalState({
            isOpen: false,
            path: null
        })
    }

    return <Modal
        opened={transactionPathModalState.isOpen}
        title={'Transaction Route'}
        closeModal={handleClose}
    >
        <Wrapper>
            <TransactionHeader>
                <div>{transactionPathModalState.path?.youGive.ticker} {transactionPathModalState.path?.youGive.amount.toFixed(6)}</div>
                <div>{transactionPathModalState.path?.youReceive.ticker} {transactionPathModalState.path?.youReceive.amount.toFixed(6)}</div>
            </TransactionHeader>
            <PathWrapper>
                {transactionPathModalState.path?.path?.map((path) => {
                    return <Path>
                        <NodesWrapper>
                            <Node>
                                {path.tokenA}
                            </Node>
                            <KeyboardArrowDown className={'arrow'}/>
                            <Node>
                                {path.dex}
                            </Node>
                            <KeyboardArrowDown  className={'arrow'}/>
                            <Node>
                                {path.tokenB}
                            </Node>
                        </NodesWrapper>
                    </Path>
                })}
            </PathWrapper>
            <ExchangedWrapper>
                <p>Exchanged</p>
                <div className={'values'}>
                    {transactionPathModalState.path?.youGive.ticker} {transactionPathModalState.path?.youGive.amount.toFixed(6)}
                    <KeyboardArrowDown className={'arrow'}/>
                    {transactionPathModalState.path?.youReceive.ticker} {transactionPathModalState.path?.youReceive.amount.toFixed(6)}
                </div>
            </ExchangedWrapper>
        </Wrapper>
    </Modal>
}

export default TransactionPath;