
const digest = 'ee15dc27fcfd209601159e7f0e0063ff4e7a886b7b892c1600a911372b82a0b1';
const classes = {"tipContainer":"_tipContainer_ufud7_1","tipContent":"_tipContent_ufud7_5"};
const css = `._tipContainer_ufud7_1{overflow:visible;position:relative}._tipContainer_ufud7_1 ._tipContent_ufud7_5{background:#3f3f46;border-radius:8px;bottom:100%;color:#fff;font-size:11px;left:-100%;opacity:0;padding:3px 6px;position:absolute;transition:opacity .5s;visibility:hidden;white-space:nowrap;z-index:1}._tipContainer_ufud7_1:hover ._tipContent_ufud7_5{opacity:1;visibility:visible}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  