import AssetsService from "../services/assets.service";
import {useQuery} from "@tanstack/react-query";
import {Chain} from "viem";
import {TGetPriceForSelectedTokensParams} from "../types/types";

export const useGetTokensForSelectedChainService = (chainId: number) => {
    return useQuery(
        {
            queryKey: ['GetTokensForSelectedChain', chainId],
            queryFn: ({signal}) => AssetsService.getTokensForSelectedChain({chainId}, signal),
            enabled: !!chainId
        }
    );
};

export const useGetPriceForSelectedTokens = (params: TGetPriceForSelectedTokensParams) => {
    return useQuery({
        queryKey: ['GetPriceForSelectedTokens', params],
        queryFn: ({signal}) => AssetsService.getPriceForSelectedTokens(params, signal),
        enabled: !!params.tokenB && !!params.tokenA,
        refetchInterval: 10000,
    })
}