import React from "react";
import Modal from "components/modal/Modal";
import {useAppContext, useWallet} from 'hooks'
import {ConnectWalletButton, Content} from "./ConnectWallet.style";
import Spinner from "components/spinner/Spinner";
import WalletIcon from "components/wallet-icon/WalletIcon";
import {BaseConnector} from "@particle-network/btc-connectkit";

const ConnectWallet: React.FC = () => {
    const {isConnectWalletModalOpened, setIsConnectWalletModalOpened} = useAppContext();

    const {connect, connectors, isPending} = useWallet()

    const closeModal = () => {
        setIsConnectWalletModalOpened(false);
    }


    return <Modal opened={isConnectWalletModalOpened} title={'Connect Wallet'} closeModal={closeModal}>

            <Content>
                {isPending ? <div style={{textAlign: 'center'}}>
                        <Spinner size={50}/>
                        <p style={{marginTop: '8px'}}>
                            Connecting wallet...
                        </p>
                    </div> :
                    <>
                        {connectors.map((connector) => {
                            const name = connector instanceof BaseConnector ? connector.metadata.name : connector.name;
                            const id = connector instanceof BaseConnector ? connector.metadata.id : connector.id;
                            return <ConnectWalletButton key={id} onClick={() => connect(connector)}>
                                <WalletIcon name={name}/>
                                {name}
                            </ConnectWalletButton>
                        })}
                    </>
                }
            </Content>


    </Modal>
}

export default ConnectWallet;