import styled, {keyframes} from "styled-components";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div<{$size: number}>`
    border: 5px solid ${({theme}) => theme.color.grayscale[200]};
    border-top: 5px solid ${({theme}) => theme.color.brand.yellow.regular};
    border-radius: 50%;
    width: ${({$size}) => $size}px;
    height: ${({$size}) => $size}px;
    animation: ${rotate} 0.5s linear infinite;
    margin: auto;
`;