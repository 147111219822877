export const formatNumberToCurrency = (
    value: number | string | undefined,
    float: boolean = true
): string => {
    if (value === undefined || value === null) return '';

    let numValue: number;

    if (typeof value === 'string') {
        numValue = parseFloat(value);
        if (isNaN(numValue)) return '';
    } else {
        numValue = value;
    }

    if (isNaN(numValue)) return '';

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    if (float) {
        return formatter.format(numValue);
    }

    const arrValue = formatter.format(numValue).split('.');
    arrValue.pop();
    return arrValue.join('');
};
