import React from "react";
import {ToastContainer, ToastContainerProps} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {ReactComponent as Success} from "assets/svgs/SuccessIcon.svg";
import {ReactComponent as Warning} from "assets/svgs/WarningIcon.svg";
import {ReactComponent as Error} from "assets/svgs/ErrorIcon.svg";
import {ReactComponent as Info} from "assets/svgs/InfoIcon.svg";
import {ReactComponent as CloseButton} from 'assets/svgs/CloseButton.svg';

interface Props extends ToastContainerProps {

}

const Toast: React.FC<Props> = (props) => {
    return (
        <ToastContainer
            style={{top: '100px', right: '70px'}}
            icon={(props) => {
                switch (props.type) {
                    case 'success':
                        return <Success/>;
                    case 'error':
                        return <Error/>;
                    case 'warning':
                        return <Warning/>;
                    case 'info':
                        return <Info/>;
                    default:
                        return null;
                }
            }}
            closeButton={({closeToast}) => <div onClick={closeToast}>
                    <CloseButton style={{cursor: 'pointer'}}/>
                </div>
            }
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            // rtl={false}
            closeOnClick={false}
            // limit={1}
            {...props}

        />
    )
}

export default Toast;