import React, {useCallback, useEffect, useMemo, useState} from "react";

import {questions} from "./questions";
import {Wrapper, QuestionsWrapper, InputStyled, SearchWrapper} from "./FAQs.style";
import FAQ from "./components/faq/FAQ";
import {ReactComponent as SearchIcon} from  'assets/svgs/SearchIcon.svg'

const FAQs: React.FC = () => {
    const [faqs, setFaqs] = useState(questions)
    const [search, setSearch] = useState('')

    const toggleFAQ = (index: number) => {
        setFaqs(faqs.map((faq, i) => {
            if (i === index) {
                faq.opened = !faq.opened
            } else {
                faq.opened = false;
            }

            return faq;
        }))
    }

    const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }, [])

    const filteredFaqs = useMemo(() => {
        return faqs.filter(faq => faq.question.toLowerCase().includes(search.toLowerCase()) || JSON.stringify(faq.answer).toLowerCase().includes(search.toLowerCase()))
    }, [faqs, search])


    return (
        <Wrapper>
            <h2>Frequently asked questions </h2>
            <SearchWrapper>
                <InputStyled onChange={handleSearch} value={search} placeholder={'Search...'}/>
                <SearchIcon />
            </SearchWrapper>
            {filteredFaqs.length === 0 ? <p>No results found</p> :
                <QuestionsWrapper>
                    {filteredFaqs.map((question, index) => (
                        <FAQ
                            toggleFAQ={toggleFAQ}
                            faq={question}
                            index={index}
                            key={question.question}
                        />
                    ))}
                </QuestionsWrapper>
            }

        </Wrapper>
    )
}

export default FAQs