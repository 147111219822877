import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 16px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border: 1px solid ${({theme}) => theme.color.grayscale[300]};
`

export const Label = styled.p.attrs({
    className: 'text-sm'
})`
    color: ${({theme}) => theme.color.grayscale[300]};
`

export const Value = styled.p.attrs({
    className: 'text-sm'
})`
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const Slippage = styled.div.attrs({
    className: 'text-sm'
})`
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const SlippageSelectMenu = styled.div`
    width: 200px;
    button {
        margin-top: 8px;
        width: 100%;
    }
    
    input {
        width: 100%;
    }
`