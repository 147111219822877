import React from "react";
import {HiddenCheckbox, Label, StyledSwitch, SwitchContainer} from "./ToggleSwitch.style";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string | React.ReactNode;
}

const ToggleSwitch: React.FC<Props> = ({checked, label, ...rest}) => {
    const handleLabelClick = (e: React.MouseEvent<HTMLLabelElement>) => {
        if ((e.target as HTMLElement).tagName !== 'A') {
            rest.onChange && rest.onChange({ target: { checked: !checked } } as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const handleCheckboxClick = () => {
        rest.onChange && rest.onChange({ target: { checked: !checked } } as React.ChangeEvent<HTMLInputElement>);
    }

    return (
        <SwitchContainer>
            <HiddenCheckbox checked={checked} {...rest}/>
            <StyledSwitch
                checked={!!checked}
                onClick={handleCheckboxClick}
            />
            <Label htmlFor={rest.id} onClick={handleLabelClick}>
                {label}
            </Label>
        </SwitchContainer>

    );
}

export default ToggleSwitch