import styled from "styled-components";

export const ToggleButton = styled.button`
    padding: 0;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({theme}) => theme.color.grayscale[100]};
`