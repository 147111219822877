import React from "react";

const PrivacyPolicy: React.FC = () => {
    return <>
        <h2>BITLiquidity Privacy Policy</h2>
        <p><strong>Effective Date</strong>: 22/07/2024</p>
        <p>
            BITLiquidity ("we," "us," or "our") respects your privacy and is committed to protecting your personal data.
            This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit
            our website and use our services (collectively, the "Services"). By accessing or using our Services, you
            agree
            to the terms of this Privacy Policy. If you do not agree with the terms, please do not use our Services.
        </p>
        <h4>1. Information We Collect</h4>
        <p>We may collect and process the following types of information:</p>
        <p>1.1 <strong>Personal Information:</strong></p>
        <ul>
            <li><strong>Contact Information:</strong> Name, email address, phone number, and mailing address.</li>
            <li><strong>Account Information:</strong> Username, password, and other registration details.</li>
            <li><strong>Financial Information:</strong> Payment details, including credit card information and
                cryptocurrency wallet addresses.
            </li>
        </ul>
        <p>1.2 <strong>Usage Data:</strong></p>
        <ul>
            <li>Information about how you interact with our Services, including IP address, browser type, operating
                system, referral URLs, pages visited, and the dates and times of your visits.
            </li>
        </ul>
        <p>1.3 <strong>Cookies and Tracking Technologies:</strong> We use cookies, web beacons, and other tracking
            technologies to collect information about your activities on our website and to enhance your user
            experience.</p>

        <h4>2. How We Use Your Information</h4>

        <p>We may use the information we collect for various purposes, including:</p>

        <p>2.1 <strong>Providing and Managing Services:</strong></p>
        <ul>
            <li> To create and manage your account, process
                transactions, and provide customer support.
            </li>
        </ul>
        <p>2.2 <strong>Improving Our Services:</strong></p>
        <ul>
            <li> To understand how users interact with our Services, identify
                areas for improvement, and develop new features.
            </li>
        </ul>
        <p>2.3 <strong>Communication:</strong></p>
        <ul>
            <li> To send you updates, newsletters, marketing materials, and other
                information related to our Services. You can opt out of receiving marketing communications at any time.
            </li>
        </ul>
        <p>2.4 <strong>Security and Compliance:</strong></p>
        <ul>
            <li> To protect our Services, detect and prevent fraud, and comply
                with legal obligations.
            </li>
        </ul>

        <h4>3. Disclosure of Your Information</h4>
        <p>We may share your information in the following circumstances:</p>

        <p>3.1 <strong>With Service Providers:</strong></p>
        <ul>
            <li> We may share your information with third-party service providers
                who perform services on our behalf, such as payment processing, data analysis, and marketing assistance.
            </li>
        </ul>
        <p>3.2 <strong>For Legal Reasons:</strong></p>
        <ul>
            <li> We may disclose your information if required by law or in response to
                valid requests by public authorities (e.g., a court or government agency).
            </li>
        </ul>
        <p>3.3 <strong>Business Transfers:</strong></p>
        <ul>
            <li>In the event of a merger, acquisition, or sale of all or a portion
                of our assets, your information may be transferred to the acquiring entity.
            </li>
        </ul>

        <h4>4. Data Security</h4>

        <p>We implement appropriate technical and organizational measures to protect your personal data from
            unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic
            storage is completely secure, and we cannot guarantee absolute security.</p>

        <h4>5. Your Data Protection Rights</h4>
        <p>Depending on your jurisdiction, you may have the following rights regarding your personal data:</p>
        <p>5.1 <strong>Access:</strong></p>
        <ul>
            <li> You have the right to request access to the personal data we hold about you.
            </li>
        </ul>
        <p>5.2 <strong>Correction:</strong></p>
        <ul>
            <li> You have the right to request that we correct any inaccuracies in your
                personal data.
            </li>
        </ul>
        <p>5.3 <strong>Deletion:</strong></p>
        <ul>
            <li> You have the right to request that we delete your personal data, subject to
                certain exceptions.
            </li>
        </ul>
        <p>5.4 <strong>Objection:</strong></p>
        <ul>
            <li> You have the right to object to the processing of your personal data in
                certain circumstances.
            </li>
        </ul>
        <p>5.5 <strong>Data Portability:</strong></p>
        <ul>
            <li>  You have the right to request a copy of your personal data in a
                structured, commonly used, and machine-readable format.
            </li>
        </ul>
        <p>To exercise any of these rights, please contact us at <a
            href="mailto:info@bitliquidity.io">info@bitliquidity.io</a>.</p>

        <h4>6. International Data Transfers</h4>
        <p>Your information may be transferred to and processed in countries other than your own. These countries may
            have data protection laws that are different from the laws of your country. We take appropriate measures to
            ensure that your information remains protected in accordance with this Privacy Policy.</p>

        <h4>7. Retention of Your Information</h4>
        <p>We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy
            Policy, unless a longer retention period is required or permitted by law.</p>

        <h4>8. Third-Party Links</h4>
        <p>Our Services may contain links to third-party websites. We are not responsible for the privacy practices or
            content of these third-party sites. We encourage you to review the privacy policies of any third-party sites
            you visit.</p>

        <h4>9. Children's Privacy</h4>
        <p>Our Services are not intended for individuals under the age of 18. We do not knowingly collect personal data
            from children under 18. If we become aware that we have collected personal data from a child under 18, we
            will take steps to delete such information.</p>

        <h4>10. Changes to This Privacy Policy</h4>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on our website and updating the "Effective Date" above. Your continued use of the Services
            after the changes become effective constitutes your acceptance of the revised Privacy Policy.</p>

        <h4>11. Contact Us</h4>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us
            at <a href="mailto:info@bitliquidity.io">info@bitliquidity.io</a>.</p>
    </>
}

export default PrivacyPolicy;