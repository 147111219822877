import React, {forwardRef} from "react";
import {Input as InputStyled, Label} from "./Input.style";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string | React.ReactNode;
    inputSize?: 'small' | 'medium'
}

const Input = forwardRef<HTMLInputElement, Props>((
    {inputSize = 'small', label, ...props}, ref
) => {

    return <div>
        {label ? <Label htmlFor={props.id}>
            {label}
        </Label> : null}
        <InputStyled
            $size={inputSize}
            ref={ref}
            {...props}
        />
    </div>
})

export default Input;