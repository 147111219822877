import styled, {css} from "styled-components";

interface OptionProps {
    $active: boolean
    $disabled?: boolean
}

export const Wrapper = styled.div`
    display: inline-flex;
    gap: 4px;
    border-radius: 8px;
    padding: 4px;
    background-color: ${({theme}) => theme.color.grayscale[800]};
`

export const Option = styled.div.attrs<OptionProps>({
    className: 'text-sm'
})`
    padding: 8px;
    border-radius: 8px;
    background-color: ${({theme}) => theme.color.grayscale[800]};
    color: ${({theme}) => theme.color.grayscale[300]};
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: ${({theme}) => theme.color.grayscale[700]};
        color: ${({theme}) => theme.color.grayscale[100]};
    }
    
    ${({$active}) => $active && css`
        background-color: ${({theme}) => theme.color.brand.yellow.regular} !important;
        color: ${({theme}) => theme.color.grayscale[900]} !important;
        pointer-events: none;
    `}
    
    ${({$disabled}) => $disabled && css`
        background-color: ${({theme}) => theme.color.grayscale[700]} !important;
        color: ${({theme}) => theme.color.grayscale[500]} !important;
        cursor: not-allowed;
    `}
`