
const digest = 'd6fbb5df7ed6ec51d41f665ca768306181bb1e7d7c1cef8eed9bd4230368d7bb';
const classes = {"copyText":"_copyText_smolu_1","copied":"_copied_smolu_9"};
const css = `._copyText_smolu_1{cursor:pointer;text-decoration-line:underline}._copyText_smolu_1:hover{opacity:.75}._copied_smolu_9{color:#a855f7;cursor:none;text-decoration-line:none}._copied_smolu_9:hover{opacity:1}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  