import styled from "styled-components";

export const Overlay = styled.div`
    position: fixed;
    inset: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    //align-items: center;
    backdrop-filter: blur(2px);
    
`

export const ModalContainer = styled.div`
    max-width: 100%;
    margin-top: 200px;
    height: fit-content;
    /*border: 1px solid var(--grayscale-100);*/
    border-radius: 16px;
    background-color: ${({theme}) => theme.color.grayscale[800]};
    padding: 24px 16px;
    max-height: calc(100% - 200px);
    overflow-y: auto;
    @media (max-width: ${({theme}) => theme.breakingPoints.sm}) {
        margin-top: 100px;
        max-height: calc(100% - 100px);
        &::-webkit-scrollbar { display: none; }
    }
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${({theme}) => theme.color.grayscale[100]};
`

export const Content = styled.div`
    overflow-y: auto;
`