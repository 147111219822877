import styled from "styled-components";
import {NavLink} from "react-router-dom";

export const Wrapper = styled.div`
    height: 100%;
    display: flex;
    gap: 24px;
`

export const Navigation = styled.div`
    width: 300px;
    height: 100%;
    background: ${({theme}) => theme.color.grayscale[800]};
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 8px;
    border-radius: 24px;
`

export const NavigationItem = styled(NavLink).attrs({
    className: 'text-md'
})`
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    text-align: start;
    text-decoration: none;
    color: ${({theme}) => theme.color.grayscale[200]};
    &:hover {
        background-color: ${({theme}) => theme.color.grayscale[700]};
    }
    &.active {
        background-color: ${({theme}) => theme.color.grayscale[600]};
    }
`

export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 520px;
    margin: 0 auto;
`