import React, {createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {isTokenExpired} from "utils";
import {setApiInterceptor} from "../config/api";

export interface AuthContextValue {
    token: string | null;
    setToken: (newToken: string | null) => void;
    isAuthenticated: boolean;
    error: string | undefined;
    setError: (error: string | undefined) => void;
    success: string | undefined;
    setSuccess: (success: string | undefined) => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextValue | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
    const [error, setError] = useState<string | undefined>();
    const [success, setSuccess] = useState<string | undefined>();
    setApiInterceptor(token);

    useEffect(() => {
        if(isTokenExpired(token) || !token){
            setToken(null);
            localStorage.removeItem('token');
        } else {
            localStorage.setItem('token', token);
        }
    }, [token]);

    const logout = useCallback(() => {
        setToken(null);
        window.localStorage.removeItem('claimr-tokne');
        window.localStorage.removeItem('has-reloaded');
        window.localStorage.removeItem('param');
    }, [])

    const contextValue = useMemo(
        () => ({
            token,
            setToken,
            isAuthenticated: !!token,
            error,
            setError,
            success,
            setSuccess,
            logout
        }),
        [token, error, success, logout]
    );

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
