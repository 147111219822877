import React, {ReactNode, useCallback} from "react";
import {Option, Wrapper} from "./OptionSwitch.style";

interface Props extends React.HTMLAttributes<HTMLDivElement>{
    options: {
        value: string | number,
        label: string | ReactNode,
        disabled?: boolean,
        tooltip?: string
    }[]
    onOptionChange: (value: string | number) => void
    activeOption: string | number,
    optionClassName?: string,
    fullWidth?: boolean
}

const OptionSwitch: React.FC<Props> = ({options, fullWidth, optionClassName, onOptionChange, activeOption, ...props}) => {

    const handleOptionChange = useCallback((value: string | number) => {
        onOptionChange(value)
    }, [onOptionChange])

    return <Wrapper
        {...props}
    >
        {options.map(option => (
            <Option
                className={optionClassName}
                key={option.value}
                onClick={() => handleOptionChange(option.value)}
                $active={activeOption === option.value}
                $disabled={option.disabled}
                data-tooltip-id={'tooltip'}
                data-tooltip-content={option.tooltip}
                data-tooltip-hidden={!option.tooltip}
            >
                {option.label}
            </Option>
        ))}
    </Wrapper>
}

export default OptionSwitch;