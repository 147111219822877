import React from "react";
import OptionSwitch from "components/option-switch/OptionSwitch";
import {SLIPPAGE_OPTIONS} from "consts/consts";
import {useAppContext} from "hooks";
import Input from "components/input/Input";
import {TTradingInterface} from "../../../../../../types/types";

const Slippage: React.FC = () => {
    const {
        swapSlippage,
        setSwapSlippage,
        transactionDuration,
        setTransactionDuration,
        slippageValue,
        setSlippageValue,
        tradingInterface,
        setTradingInterface
    } = useAppContext();

    const handleSlippageChange = (value: number | string) => {
        if(typeof value === 'string') {
            setSwapSlippage({selected: value, value: 0})
        } else {
            setSwapSlippage({selected: value, value: value})
            setSlippageValue(value.toString())
        }

    }

    const handleCustomSlippageChange = (value: string) => {
        setSlippageValue(value)
    }

    const handleDurationChange = (value: string) => {
        setTransactionDuration(value)
    }

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h5>
                Slippage
            </h5>
            <p className={'text-md'}>{slippageValue}% {swapSlippage.selected === 0.5 && 'Auto'} {swapSlippage.selected === 'custom' && 'Custom'}</p>
            </div>
            <OptionSwitch
                className={'swap-switch'}
                options={SLIPPAGE_OPTIONS}
                onOptionChange={(value) => handleSlippageChange(value)}
                activeOption={swapSlippage.selected}
            />
            {swapSlippage.selected === 'custom' && <Input
                placeholder={'Enter custom slippage'}
                value={slippageValue}
                style={{width: '100%'}}
                type={'number'}
                onChange={(e) => handleCustomSlippageChange(e.target.value)}
            />}

                <h5 style={{marginTop: '8px'}}>Trading interface</h5>
                <OptionSwitch
                    className={'swap-switch'}
                    options={[
                        {label: 'Simple', value: 'simple' as TTradingInterface},
                        {label: 'Advanced', value: 'advanced' as TTradingInterface, disabled: true, tooltip: 'Coming soon'}
                    ]}
                    onOptionChange={(value) => setTradingInterface(value as TTradingInterface)}
                    activeOption={tradingInterface}
                />

            {/*<h5>Duration</h5>*/}
            {/*<Input*/}
            {/*    placeholder={'Enter transaction duration in seconds'}*/}
            {/*    value={transactionDuration}*/}
            {/*    style={{width: '100%'}}*/}
            {/*    type={'number'}*/}
            {/*    onChange={(e) => handleDurationChange(e.target.value)}*/}
            {/*/>*/}
        </>
    );
}

export default Slippage;