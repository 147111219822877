import { createContext, useState, ReactNode, useMemo } from 'react';
import { ethers, providers } from 'ethers';
import { toast } from "react-toastify";

import erc20 from 'abis/erc20.abi.json';
import aggregationAbi from 'abis/aggregator.abi.json';
import {useAppContext, useWallet} from "../hooks";
import {Config, useChainId, useConnectorClient} from "wagmi";
import {Account, Chain, Client, Transport} from "viem";
import {useAddTransaction, useUpdateTransaction} from "../queries";
import {useETHProvider} from "@particle-network/btc-connectkit";

interface EthereumContextProps {
    approveYouGiveToken: () => Promise<void>;
    aggregateLoading: boolean;
    approveLoading: boolean;
    aggregate: () => Promise<void>;
    allowancePassed: boolean;
    setAllawencePassed: (isPassed: boolean) => void
}

export const EthereumContext = createContext<EthereumContextProps | null>(null);


interface EthereumProviderProps {
    children: ReactNode;
}

const BITLAYER_AGGREGATION_ROUTER = process.env.REACT_APP_BITLAYER_AGGREGATION_ROUTER || ''
const COREDAO_AGGREGATION_ROUTER = process.env.REACT_APP_COREDAO_AGGREGATION_ROUTER || ''
const BTC_CONTRACT_ADDRESS = '0x000000000000000000000000000000000000dEaD'

function clientToSigner(client: Client<Transport, Chain, Account> | undefined) {
    if (!client) return
    const { account, chain, transport } = client
    const network = {
        chainId: chain.id,
        name: chain.name,
        ensAddress: chain.contracts?.ensRegistry?.address,
    }
    const provider = new providers.Web3Provider(transport, network)
    const signer = provider.getSigner(account.address)
    return signer
}

export const EthereumProvider = ({ children }: EthereumProviderProps) => {
    const [aggregateLoading, setAggregateLoading] = useState(false);
    const [approveLoading, setApproveLoading] = useState(false);
    const [allowancePassed, setAllawencePassed] = useState(false);
    const {selectedChain} = useAppContext()

    const router = useMemo(() => {
        if(selectedChain?.id === 200901) {
            return BITLAYER_AGGREGATION_ROUTER
        } else if(selectedChain?.id === 1116) {
            return COREDAO_AGGREGATION_ROUTER
        } else {
            return ''
        }
    }, [selectedChain])

    const {
        youGiveToken,
        youGetToken,
        tokensDetails,
        setIsConfirmSwapModalOpened,
        transactionHistoryData,
        transactionHistoryPagination,
        setTransactionHistoryPagination
    } = useAppContext()
    const {address} = useWallet()

    const chainId = useChainId()

    const { provider: particleProvider} = useETHProvider()
    const { data: wagmiClient } = useConnectorClient<Config>({ chainId })


    const signer  = useMemo(() => {
        if(wagmiClient){
            return clientToSigner(wagmiClient)
        }
        if(particleProvider.account) {
            const provider = new providers.Web3Provider(particleProvider, 'any')
            return provider.getSigner(particleProvider.account)
        }
        return undefined
    }, [particleProvider, wagmiClient])

    const getAllowance = async () => {
        if (!signer || !youGiveToken?.contractAddress) return;
        const contract = new ethers.Contract(youGiveToken?.contractAddress, erc20, signer);
        const allowance = await contract.allowance(address, router);

        return ethers.utils.formatUnits(allowance, youGiveToken?.decimals);
    }

    const approveYouGiveToken = async () => {
        if (!signer || !youGiveToken?.contractAddress) return;
        const contract = new ethers.Contract(youGiveToken?.contractAddress, erc20, signer);
        const maxAllowance = ethers.constants.MaxUint256;
        setApproveLoading(true)
        try {
            const approveTx = await contract.approve(router, maxAllowance);
            await approveTx.wait();
            const allowance = await getAllowance();
            if((tokensDetails && youGiveToken && allowance) && Number(allowance) > tokensDetails[youGiveToken?.id].amount) {
                setAllawencePassed(true);
            }

            toast.success(`You have successfully approved ${youGiveToken.ticker}.`);
        } catch (error: any) {
            toast.error('Error while approving. Please try again later.');
        } finally {
            setApproveLoading(false)
        }
    }

    const addTransactionMutation = useAddTransaction()
    const updateTransactionMutation = useUpdateTransaction()

    const aggregateFromBTC = async (contract: ethers.Contract) => {
        return await contract.aggregateFromBTC(
            tokensDetails?.aggregateData.hops,
            tokensDetails?.aggregateData.indices,
            tokensDetails?.aggregateData.additionalData,
            {
                value: tokensDetails?.aggregateData.baseInput
            }
        )
    }


    const aggregate = async () => {
        if (!signer) return;

        if(!tokensDetails?.aggregateData) {
            toast.error('Aggregation route not found!');
            return;
        }
        let txHash: string;
        const contract = new ethers.Contract(router, aggregationAbi, signer);
        setAggregateLoading(true)
        try {
            let methodeName = 'aggregate';
            if(youGetToken?.contractAddress === BTC_CONTRACT_ADDRESS) {
                methodeName = 'aggregateToBTC'
            }

            let aggregateTx;

            if(youGiveToken?.contractAddress === BTC_CONTRACT_ADDRESS) {
                aggregateTx = await aggregateFromBTC(contract)
            } else {
                aggregateTx = await contract[methodeName](
                    tokensDetails?.aggregateData.baseInput,
                    tokensDetails?.aggregateData.hops,
                    tokensDetails?.aggregateData.indices,
                    tokensDetails?.aggregateData.additionalData
                );
            }

            txHash = aggregateTx.hash;
            //TODO: Dodaj transakciju
            await addTransactionMutation.mutateAsync({
                transactionHash: txHash,
                userAddress: address || '',
                tokenA: youGiveToken?.id || '',
                tokenB: youGetToken?.id || '',
                tickerTokenA: youGiveToken?.ticker || '',
                tickerTokenB: youGetToken?.ticker || '',
                amountTokenA: youGiveToken ? tokensDetails[youGiveToken?.id].amount : 0,
                amountTokenB: youGetToken ? tokensDetails[youGetToken?.id].amount : 0,
                chain: String(chainId),
                bestPath: tokensDetails?.bestPath
            })
            //TODO: Dohvati sve transakcije
            if(transactionHistoryPagination.skip === 0) {
                await transactionHistoryData.refetch()
            } else {
                setTransactionHistoryPagination((prev) => ({...prev, skip: 0}))
            }
            setAggregateLoading(false)
            setIsConfirmSwapModalOpened(false);

            try {
                await aggregateTx.wait();

                let url = ''

                switch (selectedChain?.id) {
                    case 200901:
                        url = `https://www.btrscan.com/tx/${aggregateTx.hash}`
                        break;
                    case 1116:
                        url = `https://scan.coredao.org/tx/${aggregateTx.hash}`
                        break;
                    default:
                        break;
                }
                toast.success(
                    <>You have successfully swapped {youGiveToken && tokensDetails[youGiveToken?.id].amount}{youGiveToken?.ticker} for {youGetToken && tokensDetails[youGetToken?.id].amount}{youGetToken?.ticker}. View on <a href={url} target={'_blank'}>{selectedChain?.name} </a></>
                );

                await updateTransactionMutation.mutateAsync({
                    state: 'COMPLETED',
                    id: txHash
                })
                if(transactionHistoryPagination.skip === 0) {
                    await transactionHistoryData.refetch()
                } else {
                    setTransactionHistoryPagination((prev) => ({...prev, skip: 0}))
                }

            } catch (e) {
                await updateTransactionMutation.mutateAsync({
                    state: 'FAILED',
                    id: txHash
                })

                if(transactionHistoryPagination.skip === 1) {
                    await transactionHistoryData.refetch()
                } else {
                    setTransactionHistoryPagination((prev) => ({...prev, skip: 0}))
                }
                toast.error('Transaction failed. Please try again.');
            }
        } catch (error: any) {
            toast.error('Transaction failed. Please try again.');
        } finally {

            setAggregateLoading(false)
        }
    }


    return (
        <EthereumContext.Provider value={{
            approveYouGiveToken,
            aggregateLoading,
            approveLoading,
            aggregate,
            allowancePassed,
            setAllawencePassed
        }}>
            {children}
        </EthereumContext.Provider>
    );
};
