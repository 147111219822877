import React, {useCallback, useMemo} from "react";

import Dropdown from "components/dropdown/Dropdown";
import DropdownItem from "components/dropdown/item/Item";

import {
    MaxButton,
    Row,
    SearchTokenInput,
    SelectTokenList,
    SelectTokenWrapper,
    TokenInput,
    Wrapper
} from "./Token.style";
import {TToken, TTokenValues} from "types/types";
import {formatNumberToCurrency} from "../../../../../../utils";
import Skeleton from "react-loading-skeleton";
import {useAppContext} from "../../../../../../hooks";

interface Props {
    tokens:  TToken[]
    selectedToken: TToken | undefined;
    setToken: React.Dispatch<React.SetStateAction<TToken | undefined>>;
    disabledToken: TToken | undefined;
    detailsLoading: boolean;
    details: TTokenValues | undefined;
    youReceive?: boolean;
    amount?: string;
    setAmount?: React.Dispatch<React.SetStateAction<string>>,
}

const Token: React.FC<Props> = ({tokens, amount, setAmount, youReceive, disabledToken, details, detailsLoading, setToken, selectedToken}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const {slippageValue } = useAppContext()

    const filteredTokens = useMemo(() => {
        if (!tokens) return [];
        if(search === '') return tokens;
        return tokens.filter((token) => {
            return token.name.toLowerCase().includes(search.toLowerCase().trim()) ||
                token.ticker.toLowerCase().includes(search.toLowerCase().trim())
        })
    }, [search, tokens]);

    const handleSelectToken = useCallback((token: TToken) => {
        setIsOpen(false);
        setToken(token);
    }, [setToken])

    const handleAmountChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setAmount && setAmount(e.target.value)
    }, [setAmount])

    const handleMaxClick = useCallback(() => {
        let amount = details?.balance.toString() || '0'
        if (amount.length > 8) {
            amount = amount.slice(0, 8);
        }
        setAmount && setAmount(amount)
    }, [details?.balance, setAmount])


    return <Wrapper>
        <Row>
            <p className={'text-sm'}>You {youReceive ? 'receive' : 'give'}</p>
            <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                <p className={'text-sm'}>Balance ~{details?.balance?.toFixed(6)} </p>
                {!youReceive ? <MaxButton onClick={handleMaxClick}>MAX</MaxButton> : null}
            </div>

        </Row>
        <Row>
            <Dropdown
                label={selectedToken?.ticker || 'Select token'}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            >
                <SelectTokenWrapper>
                    <p className={'text-lg'}>Select token</p>
                    <SearchTokenInput
                        placeholder={'Search by token name'}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <SelectTokenList>
                        {filteredTokens.map((token) => (
                            <DropdownItem
                                onClick={() => handleSelectToken(token)}
                                key={token.id}
                                selected={selectedToken?.id === token.id}
                                disabled={disabledToken?.id === token.id}
                            >
                                {token.ticker}
                            </DropdownItem>
                        ))}
                    </SelectTokenList>
                </SelectTokenWrapper>
            </Dropdown>
            {youReceive && detailsLoading ? <Skeleton width={100} height={20}/> : <TokenInput placeholder={'0'} type={'number'} readOnly={youReceive} value={amount} onChange={handleAmountChange}/>}
        </Row>
        <Row>
            <p className={'text-sm'}>{selectedToken?.name}</p>
            {detailsLoading ? <Skeleton width={150} height={'100%'}/> : <p className={'text-sm'}>~{formatNumberToCurrency(details?.price)}</p>}
        </Row>
    </Wrapper>
}

export default Token;