import React, {useCallback, useMemo} from "react";

import Dropdown, {DropdownItem} from "components/dropdown/Dropdown";

import {ReactComponent as ArrowDropdown} from "assets/svgs/ArrowDropdown.svg";

import {PageSelect, PageSelectItem, Wrapper} from "./Pagination.style";
import {TPaginationReturnType} from "../../../hooks/usePagination";


interface Props {
    pagination: TPaginationReturnType
}

const paginationValues = [5, 10, 20, 50, 100];

const Pagination: React.FC<Props> = ({
                                         pagination
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const {
        from,
        to,
        total,
        perPage,
        handlePerPage,
        handleNextPage,
        handlePreviousPage,
        isNextPageDisabled,
        isPreviousPageDisabled,
        numberOfPages,
        currentPage,
        setCurrentPage,
        paginationRange
    } = pagination

    const dropdownToggleRef = React.useRef<HTMLDivElement>(null);

    const handlePerPageSelect = useCallback((value: number) => {
        handlePerPage(value)
        setIsOpen(false)
    }, [handlePerPage])


    return <Wrapper>
        <div className={'pagination-info'}>
            <div className={'rows-per-page'}>
                Rows per page:
                <div ref={dropdownToggleRef} className={'per-page-dropdown'}>
                    {perPage}<ArrowDropdown/>
                </div>
            </div>
            <div>Showing: {from} - {to} of {total}</div>
        </div>
        <div className={'page-select'}>
            <PageSelectItem onClick={handlePreviousPage} style={{pointerEvents: isPreviousPageDisabled ? 'none' : 'all'}}>
                <PageSelect $disabled={isPreviousPageDisabled} style={{transform: 'rotate(180deg)'}}/>
            </PageSelectItem>

            {/*{numberOfPages < 5 ?*/}
            {/*    Array.from(Array(numberOfPages).keys())*/}
            {/*        .map((_, index) =>*/}
            {/*            <PageSelectItem*/}
            {/*                key={index}*/}
            {/*                $active={index === currentPage - 1}*/}
            {/*                onClick={() => setCurrentPage(index + 1)}*/}
            {/*            >*/}
            {/*                {index + 1}*/}
            {/*            </PageSelectItem>*/}
            {/*        )*/}
            {/*    : Array.from(Array(3).keys())*/}
            {/*        .map((_, index) =>*/}
            {/*            <PageSelectItem*/}
            {/*                key={index}*/}
            {/*                $active={index === currentPage - 1}*/}
            {/*                onClick={() => setCurrentPage(index + 1)}*/}
            {/*            >*/}
            {/*                {index + 1}*/}
            {/*            </PageSelectItem>*/}
            {/*        )*/}
            {/*}*/}
            {paginationRange?.map((value, index) => {
                if(value === '...') {
                    return <PageSelectItem style={{pointerEvents: 'none'}} key={index} $active={false}>{value}</PageSelectItem>
                }
                return <PageSelectItem
                    key={index}
                    $active={value === currentPage}
                    onClick={() => setCurrentPage(Number(value))}>{value}</PageSelectItem>
            })}
            <PageSelectItem onClick={handleNextPage} style={{pointerEvents: isNextPageDisabled ? 'none' : 'all'}}>
                <PageSelect $disabled={isNextPageDisabled}/>
            </PageSelectItem>
        </div>
        <Dropdown
            toggleElementRef={dropdownToggleRef}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            {paginationValues.map((value) => (
                <DropdownItem
                    key={value}
                    selected={value === perPage}
                    onClick={() => handlePerPageSelect(value)}
                >
                    {value}
                </DropdownItem>
            ))}
        </Dropdown>
    </Wrapper>
}

export default Pagination;