import { toast } from "react-toastify";
import CodeService from "services/code.service";

export const useCode = () => {
    const getTaskCode = async (handle: string) => {
        try {
            const response = await CodeService.getCode({telegramHandle: handle});
            return response;
        } catch (error) {
            toast("Unable to generate code, handle could be missing or is invalid", {type: "error"})
        }

    }

    return {
        getTaskCode
    }
}