import React from "react";

import {SpinnerWrapper} from "./Spinner.style";

interface Props {
    size: number;
}

const Spinner: React.FC<Props> = ({size}) => {
    return <SpinnerWrapper $size={size} />
}

export default Spinner;