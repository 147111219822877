import styled, {css} from "styled-components";

interface Props {
    $type: 'success' | 'error' | 'warning' | 'info'
}

export const Wrapper = styled.div<Props>`
    padding: 16px;
    border-radius: 16px;
    font-size: ${({theme}) => theme.fontSize.text.lg};
    line-height: ${({theme}) => theme.lineHeight.xs};
    letter-spacing: 0.25px;
    color: ${({theme}) => theme.color.grayscale[100]};
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: pre-wrap;
    justify-content: space-between;

    .close-button {
        cursor: pointer;   
    }
    
    ${({$type}) => {
        switch ($type) {
            case 'success': 
                return css`
                    background-color: rgba(84, 243, 58, 0.08);
                    border: 1px solid ${({theme}) => theme.color.success};
                `
            case 'error':
                return css`
                    background-color: rgba(243, 58, 58, 0.08);
                    border: 1px solid ${({theme}) => theme.color.error};
                `
            case 'warning':
                return css`
                    background-color: rgba(243, 147, 58, 0.08);
                    border: 1px solid ${({theme}) => theme.color.warning};
                `
            case 'info':
                return css`
                    background-color: rgba(58, 77, 243, 0.08);
                    border: 1px solid ${({theme}) => theme.color.info};
                `
        }
    }}
`

export const Content = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`