import React, {useCallback, useEffect} from "react";
import {ConnectWalletButton, Content, Navigation, NavigationItem, TokenWrapper, Wrapper} from "./Swap.style";
import Token from "./components/token/Token";
import SwapButton from "./components/swap-button/SwapButton";
import Summary from "./components/summary/Summary";
import {useAppContext, useWallet} from "hooks";
import {ReactComponent as Tune} from "assets/svgs/Tune.svg";
import {ReactComponent as Arrow} from "assets/svgs/KeyboardArrowDown.svg";
import Slippage from "./components/slippage/Slippage";
import Button from "../../../../components/button/Button";

const Swap: React.FC = () => {
    const {
        setIsConnectWalletModalOpened,
        youGetToken,
        youGiveToken,
        setYouGetToken,
        setYouGiveToken,
        swapTokens,
        tokens,
        tokensDetails,
        tokensDetailsLoading,
        youGiveAmount,
        setYouGiveAmount,
        youGetAmount,
        swapSlippage,
        setIsConfirmSwapModalOpened
    } = useAppContext();

    const [tab, setTab] = React.useState<'swap' | 'limit' | 'settings'>('swap')

    const {isConnected} = useWallet()

    // const { getClamrToken } = useAuth();

    // useEffect(() => {
    //     getClamrToken();
    //     const hasReloaded = window.localStorage.getItem("has-reloaded");
    //     if(!hasReloaded || hasReloaded !== "true") {
    //         window.localStorage.setItem("has-reloaded", "false");
    //     }
    // }, []);

    const handleOpenConnectModal = useCallback(() => {
        setIsConnectWalletModalOpened(true)
    }, [setIsConnectWalletModalOpened])


    const handleTabChange = useCallback((tab: 'swap' | 'limit' | 'settings') => {
        setTab(tab)
    }, [])

    const handleOpenConfirmSwapModal = useCallback(() => {
        setIsConfirmSwapModalOpened(true)
    }, [setIsConfirmSwapModalOpened])

    const youGiveTokenDetails = tokensDetails && youGiveToken ? tokensDetails[youGiveToken.id] : undefined

    const insufficientBalance = youGiveTokenDetails && youGiveTokenDetails.balance < youGiveTokenDetails.amount
    
    const invalidPair = (youGiveToken?.ticker === 'BTC' && youGetToken?.ticker === 'WBTC') || (youGiveToken?.ticker === 'WBTC' && youGetToken?.ticker === 'BTC') || (youGiveToken?.ticker === 'CORE' && youGetToken?.ticker === 'WCORE') || (youGiveToken?.ticker === 'WCORE' && youGetToken?.ticker === 'CORE') || (youGiveToken?.ticker === 'WCORE' && youGetToken?.ticker === 'Native CORE')|| (youGiveToken?.ticker === 'Native CORE' && youGetToken?.ticker === 'WCORE')|| (youGiveToken?.ticker === 'Native CORE' && youGetToken?.ticker === 'CORE')|| (youGiveToken?.ticker === 'CORE' && youGetToken?.ticker === 'Native CORE')    

    return <Wrapper>
        <Navigation>
            {tab === 'settings' ?
                <NavigationItem $active={true} onClick={() => handleTabChange('swap')}><Arrow className={'back-arrow'}/>Settings</NavigationItem> : <>
                    <div style={{display: 'flex', gap: '24px'}}>
                        <NavigationItem
                            onClick={() => handleTabChange('swap')}
                            $active={tab === 'swap'}
                        >
                            Swap
                        </NavigationItem>
                        <NavigationItem
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Coming soon"
                            $active={tab === 'limit'}
                            style={{cursor: 'not-allowed'}}
                        >
                            Limit
                        </NavigationItem>
                    </div>
                    <Tune className={swapSlippage.selected !== 0.5 ? 'settings-changed' : ''} onClick={() => handleTabChange('settings')}/>
                </>}

    </Navigation>
    <Content>
        <TokenWrapper>
            {tab === 'swap' && <>
                {/*<OptionSwitch*/}
                {/*    className={'swap-switch'}*/}
                {/*    options={SWAP_TYPE}*/}
                {/*    onOptionChange={(option) => handleSwapTypeChange(String(option))}*/}
                {/*    activeOption={swapType}*/}
                {/*/>*/}
                <Token
                    tokens={tokens}
                    selectedToken={youGiveToken}
                    setToken={setYouGiveToken}
                    disabledToken={youGetToken}
                    detailsLoading={tokensDetailsLoading}
                    details={(tokensDetails && youGiveToken) ? tokensDetails[youGiveToken?.id] : undefined}
                    amount={youGiveAmount}
                    setAmount={setYouGiveAmount}
                />
                <SwapButton onClick={swapTokens}/>
                <Token
                    tokens={tokens}
                    selectedToken={youGetToken}
                    setToken={setYouGetToken}
                    disabledToken={youGiveToken}
                    detailsLoading={tokensDetailsLoading}
                    details={(tokensDetails && youGetToken) ? tokensDetails[youGetToken?.id] : undefined}
                    youReceive
                    amount={youGetAmount}
                />
            </>}
            {tab === 'settings' && <>
                <Slippage/>
            </>}
        </TokenWrapper>
        {tab === 'swap' && <Summary setTab={setTab} summary={tokensDetails?.summary} summaryLoading={tokensDetailsLoading}/>}
    </Content>
        {tab === 'swap' && <>
        {isConnected ?
            <Button
                onClick={handleOpenConfirmSwapModal}
                variant={'primary'}
                fullWidth
                disabled={tokensDetailsLoading || insufficientBalance || invalidPair}
            >
                {invalidPair ? 'Unsupported pair' : insufficientBalance ? 'Insufficient Balance' :  'Swap'}
            </Button> :
            <ConnectWalletButton onClick={handleOpenConnectModal}>
                Connect Wallet
            </ConnectWalletButton>
        }

        </>}
</Wrapper>
}

export default Swap;