import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import SettingsService from "services/settings.service";

export const useSettings = () => {
    const [receiveNewsletters, setReceiveNewsletters] = useState<boolean>(false)
    const [dontReceiveSomething, setDontReceiveSomething] = useState<boolean>(false)

    const updateMarketingPreferences = useMutation({mutationFn: SettingsService.updateMarketingPreferences})

    const handleUpdateMarketingPreferences = (receive: boolean) => {
        updateMarketingPreferences.mutate({marketingNotifications: receive})
        setReceiveNewsletters(receive)
    }

    return {
        receiveNewsletters,
        setReceiveNewsletters,
        dontReceiveSomething,
        setDontReceiveSomething,
        handleUpdateMarketingPreferences
    }
}