import styled from "styled-components";

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${({theme}) => theme.color.grayscale[100]};
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 430px;
    max-width: 100%;
`

export const ConnectWalletButton = styled.button.attrs({
    className: 'text-md'
})`
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: ${({theme}) => theme.color.grayscale[900]};
    color: ${({theme}) => theme.color.grayscale[100]};
    font-weight: 400;
    letter-spacing: .5px;
    cursor: pointer;

`