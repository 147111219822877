import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
 #myClaimrWidget {
    iframe {
        height: inhreit;
    }
 }
`