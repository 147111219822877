import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Theme from "styles/Theme";
import {GlobalStyle} from "styles/Global.style";
import {BrowserRouter} from "react-router-dom";
import Toast from "components/toast/Toast";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <BrowserRouter>
      <Theme>
          <GlobalStyle/>
          <Toast/>
            <App />
      </Theme>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
