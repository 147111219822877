import styled from "styled-components";
import {NavLink} from "react-router-dom";
import DropdownItem from "components/dropdown/item/Item";

export const Wrapper = styled.header`
    position: fixed;
    left: 0; 
    right: 0; 
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    z-index: 3;
    background-color: ${({theme}) => theme.color.grayscale[900]};
    
    .left-section {
        display: flex;
        gap: 20px;
        align-items: center;
    }
    
    .right-section {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    @media (max-width: ${({theme}) => theme.breakingPoints.lg}) {
        padding: 24px 16px;
    }

    @media (max-width: ${({theme}) => theme.breakingPoints.md}) {
        .left-section {
            width: 100%;
            justify-content: space-between;
        }
        
        .navigation-items {
            display: none;
        }
    }
`

export const Navigation = styled.nav`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 40px;
    align-items: center;

    ul {
        align-items: center;
        display: flex;
        gap: 40px;
        list-style: none;
    }

    @media (max-width: ${({theme}) => theme.breakingPoints.md}) {
        .right-section {
            display: none;
        }
    }
`

export const Link = styled(NavLink).attrs({
    className: 'text-lg'
})`
    color: ${({theme}) => theme.color.grayscale[400]};
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    &:hover {
        color: ${({theme}) => theme.color.grayscale[100]};
    }
    &.active {
        color: ${({theme}) => theme.color.grayscale[100]};
    }
`

export const UserOptionItem = styled(DropdownItem)`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 200px;
`

export const NetworkSelectToggleButton = styled.button.attrs({
    className: 'text-lg'
})`
    cursor: pointer;
    padding: 10px 14px;
    border-radius: 16px;
    color: ${({theme}) => theme.color.grayscale[200]};
    display: flex;
    align-items: center;
    gap: 8px;
    border: 2px solid ${({theme}) => theme.color.grayscale[200]};
    white-space: nowrap;
`

export const WalletConnectedWrapper = styled.div.attrs({
    className: 'text-md'

})`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 16px;
    background-color: ${({theme}) => theme.color.grayscale[600]};
    cursor: pointer;
    
    .account-address {
        background-color: ${({theme}) => theme.color.grayscale[800]};
        padding: 4px 8px;
        border-radius: 12px;
    }
`
export const TargetBurger = styled.div`
    display: none;
    transition: all 0.5s ease;
    width: 18px;
    height: 12px;

    &:hover {
        cursor: pointer;
        opacity: 0.45;
    }

    @media (max-width: ${({theme}) => theme.breakingPoints.md}) {
        display: block;
    }
`;

export const Buns = styled.ul`
  list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  padding: 0;
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), color 1s cubic-bezier(0.23, 1, 0.32, 1);
  transform: translateZ(0);
  color: #fff;
`;

export const Bun = styled.li`
  width: 100%;
  height: 2px;
    border-radius: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -0.75px;
  transform: translateY(-5.625px) translateZ(0);
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1), background-color 1s cubic-bezier(0.23, 1, 0.32, 1);

  &:nth-child(2) {
    transform: translateY(0) translateZ(0);
  }

  &:nth-child(3) {
    transform: translateY(5.625px) translateZ(0);
  }

  &.toggled {
    transform: rotate(45deg) translateZ(0);
  }

  &:nth-child(3).toggled {
    transform: rotate(-45deg) translateZ(0);
  }
`;

export const MobileNavigation = styled.nav`
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: fixed;
    top: 100px;
    left: 16px;
    right: 16px;
    z-index: 2;
    background-color: ${({theme}) => theme.color.grayscale[800]};
    padding: 8px;
    border-radius: 8px;
    transition: transform 0.5s ease;
    transform: translateY(-150%);
    border: 1px solid ${({theme}) => theme.color.grayscale[300]};
    
    &.open {
        transform: translateY(0);
    }

    .mobile-nav-item {
        width: 100%;
        padding: 12px 16px;
        border-radius: 8px;
    }
    
    a {
        width: 100%;
        display: block;
    }
`