import { apiInstance } from "config/api";

type TCodeServiceParams = {
    telegramHandle: string
}

class  CodeService {
    static async getCode(params: TCodeServiceParams){
        const data = await apiInstance.post('/code/get', params)
        return data;
    }
}

export default CodeService