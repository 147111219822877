import styled, {css} from "styled-components";

interface ItemProps {
    $active: boolean
    $disabled?: boolean
}

export const Wrapper = styled.div.attrs<ItemProps>({
    className: 'text-md'
})`
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({theme}) => theme.color.grayscale[100]};
    &:hover {
        background-color: ${({theme}) => theme.color.grayscale[700]};
    }
    
    ${({$active}) => $active && css`
        background-color: ${({theme}) => theme.color.grayscale[600]};
        pointer-events: none;
        
    `}
    
    ${({$disabled}) => $disabled && css`
        pointer-events: none;
        color: ${({theme}) => theme.color.grayscale[400]};
    `}
`