import {apiInstance} from "../config/api";
import {
    TAddTransactionParams,
    TGetTransactionsParams,
    TTransaction,
    TUpdateTransactionParams,
    TWithPagination
} from "../types/types";

class TransactionsService {
    static async getTransactions(params: TGetTransactionsParams, signal?: AbortSignal) {
        return await apiInstance.get<TWithPagination<TTransaction>>(
            `/transactions/token-a/${params.tokenA}/token-b/${params.tokenB}${params.filter === 'mine' ? `/address/${params.address}` : ''}`,
            {
                params: {
                    skip: params.skip,
                    limit: params.limit
                },
                signal
            }
        )
    }

    static async getTransaction(id: string, signal?: AbortSignal) {
        return await apiInstance.get<TTransaction>(`/transactions/${id}`, {signal})
    }

    static async updateTransaction(params: TUpdateTransactionParams, signal?: AbortSignal) {
        return await apiInstance.patch<TTransaction>(`/transactions/${params.id}`, {
            state: params.state
        }, {signal})
    }

    static addTransaction(transaction: TAddTransactionParams) {
        return apiInstance.post<TTransaction>('/transactions', transaction)
    }
}

export default TransactionsService;