import styled, {css} from "styled-components";
import Input from "../../../../components/input/Input";

interface Props {
    $opened: boolean
}

export const Wrapper = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.color.grayscale[600]};
`

export const Question = styled.div.attrs<Props>({
    className: 'text-lg'
})`
    cursor: pointer;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: center;
    font-weight: 500;
    ${({$opened}) => $opened && css`
        background-color: ${({theme}) => theme.color.grayscale[700]};

        svg {
            transform: rotate(180deg);
        }
    `}
    
    .arrow {
        height: 100%;
        min-width: 24px;
        display: flex;
        align-items: center;
    }

`

export const ContentWrapper = styled.div<{ maxHeight: number | undefined, $opened: boolean }>`
    max-height: ${(p) => `${p.maxHeight}px`};
    transition: max-height 0.25s ease-in-out;
    overflow: hidden;
    ${({$opened}) => $opened && css`
        border-top: 1px solid ${({theme}) => theme.color.grayscale[600]};
    `}
    
`;

export const Answer = styled.div.attrs<Props>({
    className: 'text-md'

})`
    color: ${({theme}) => theme.color.grayscale[200]};
    font-weight: 400;
    padding: 16px 24px;
    ul {
        padding-left: 16px;
        :last-child {
            margin-bottom: 0;
        }
        li {
            margin-bottom: 24px;
        }
    }
`

