import React from "react";
import Alert from "components/alert/Alert";


const KycVerification: React.FC = () => {
    return (
        <>
            <h3>KYC verification</h3>
            <Alert type={'info'}>
                KYC verification coming soon!
            </Alert>
        </>
    )
}

export default KycVerification