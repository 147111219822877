import React, {ButtonHTMLAttributes, forwardRef, memo} from "react";

import {Wrapper} from "./Button.style";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "primary" | "outlined" | "text" | 'filled';
    size?: "small" | "large";
    fullWidth?: boolean;
    children: React.ReactNode;
}

const Button = forwardRef<HTMLButtonElement, Props>(
    ({
         variant = 'primary',
         size = 'small',
         fullWidth,
         children,
         ...props
     }, ref) => {


        return <Wrapper
            $variant={variant}
            $size={size}
            $fullWidth={fullWidth}
            ref={ref}
            {...props}
        >
            {children}
        </Wrapper>
    }
)

export default memo(Button);