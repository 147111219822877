import {apiInstance} from "../config/api";
import {
    TGetPriceForSelectedTokensParams,
    TGetTokesForSelectedChainParams,
    TToken,
    TTokenPriceResponse
} from "types/types";

class AssetsService {
    static async getTokensForSelectedChain(params: TGetTokesForSelectedChainParams, signal?: AbortSignal) {
        return apiInstance.get<TToken[]>(`/assets/${params.chainId}`, {signal})
    }

    static async getPriceForSelectedTokens(params: TGetPriceForSelectedTokensParams, signal?: AbortSignal) {
        return apiInstance.post<TTokenPriceResponse>(`/assets/price`, params, {signal})
    }
}

export default AssetsService;