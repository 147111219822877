
export const questions = [
    {
        question: 'What is BITLiquidity?',
        answer: <>BITLiquidity is a cutting-edge aggregator for the Bitcoin ecosystem that provides an all-in-one interface for seamless swaps, advanced borrowing/lending services, and optimal trade execution. It consolidates liquidity from various sources to offer users the best possible outcomes.</>,
        opened: false
    },
    {
        question: 'What services does BITLiquidity offer?',
        answer: <>BITLiquidity offers a range of services including:
        <ul>
            <li>Trading Aggregation: Combines diverse liquidity pools to find the best trade rates.</li>
            <li>Earning Platform: Allows users to earn by staking and participating in liquidity pools.</li>
            <li>Bridge: Facilitates the transfer of assets across different networks.</li>
            <li>Lending and Borrowing: Provides advanced financial services for borrowing and lending digital assets.</li>
        </ul></>,
        opened: false
    },
    {
        question: 'How does BITLiquidity optimize trades?',
        answer: 'BITLiquidity aggregates offers from multiple markets, finds the best rates for token pairs, and executes transactions to maximize returns. This is achieved through its sophisticated Aggregator, Core, and Transaction Handler modules.',
        opened: false
    },
    {
        question: 'What problems does BITLiquidity solve?',
        answer: 'BITLiquidity addresses liquidity fragmentation on the Bitcoin blockchain by consolidating various liquidity pools. This minimizes slippage, enhances trading precision, and simplifies transactions by aggregating different marketplaces.',
        opened: false
    },
    {
        question: 'Who can benefit from using BITLiquidity?',
        answer: <>
        <ul>
            <li><strong>Traders</strong>: Seeking optimal trade execution and liquidity.</li>
            <li><strong>Earning Platform</strong>: Allows users to earn by staking and participating in liquidity pools.</li>
            <li><strong>Bridge</strong>: Facilitates the transfer of assets across different networks.</li>
            <li><strong>Lending and Borrowing</strong>: Provides advanced financial services for borrowing and lending digital assets.</li>
        </ul></>,
        opened: false
    },
    {
        question: 'How does BITLiquidity ensure security?',
        answer: 'BITLiquidity ensures security by requiring users to connect their wallets to a single smart contract, minimizing potential vulnerabilities. Additionally, it employs rigorous security protocols to protect user assets and data.',
        opened: false
    },
    {
        question: 'What are the economic advantages of using BITLiquidity?',
        answer: 'BITLiquidity offers remarkably low transaction fees and access to the best trade rates across various Automated Market Maker (AMM) Decentralized Exchanges (DEXs). The platform\'s tokenomics include a buyback and liquidity-refill mechanism to avoid inflation and provide revenue for stakers.',
        opened: false
    },
    {
        question: 'How does BITLiquidity support its continuous growth and innovation?',
        answer: 'BITLiquidity operates on a sustainable revenue model. A portion of the earnings fee is used to repurchase and add BITL tokens to the liquidity wallet, ensuring continuous growth and innovation while avoiding inflation.',
        opened: false
    },
    {
        question: 'What is the future vision of BITLiquidity?',
        answer: 'BITLiquidity aims to become a pivotal platform within the Bitcoin ecosystem, offering an intuitive and efficient user experience. By leveraging innovative technology, strategic partnerships, and a user-centric approach, BITLiquidity aspires to be the go-to platform for decentralized finance on the Bitcoin blockchain.',
        opened: false
    },
    {
        question: 'How can I get started with BITLiquidity?',
        answer: <>You can start by visiting the BITLiquidity website <a href="https://bitliquidity.io/" target="_blank">here</a>. There, you can find more information, access the platform, and explore the whitepaper and other resources.</>,
        opened: false
    },
    {
        question: 'What are BRC20 tokens and how does BITLiquidity support them?',
        answer: 'BRC20 tokens are a type of fungible token on the Bitcoin blockchain, similar to ERC20 tokens on Ethereum. BITLiquidity supports BRC20 tokens by aggregating liquidity and providing optimal trade execution for these tokens, making it easier for users to trade and interact with them.',
        opened: false
    },
    {
        question: 'How does BITLiquidity ensure data accuracy and reliability?',
        answer: 'BITLiquidity employs sophisticated data scraping techniques and integrates with APIs from various trading platforms. This modular approach ensures real-time data collection and provides users with accurate insights into market conditions, including order book depths, trade histories, and liquidity levels.',
        opened: false
    },
    {
        question: 'What makes BITLiquidity different from other liquidity aggregators?',
        answer: 'BITLiquidity stands out by focusing on the Bitcoin blockchain, offering a comprehensive solution that includes trading aggregation, earning opportunities, bridging assets, and advanced lending/borrowing services. It also emphasizes security, low transaction fees, and user-centric design.',
        opened: false
    },
    {
        question: 'How does BITLiquidity\'s user interface enhance the trading experience?\n',
        answer: 'BITLiquidity\'s user interface is designed for simplicity and ease of use, catering to both seasoned professionals and average users. This intuitive design breaks down barriers to entry, making it straightforward for anyone to navigate the complexities of cryptocurrency trading and maximize their opportunities.',
        opened: false
    },
    {
        question: 'What strategic partnerships does BITLiquidity have to enhance its services?',
        answer: 'BITLiquidity collaborates with various platforms and services, including decentralized exchanges (DEXs), centralized exchanges (CEXs), and other liquidity providers. These strategic partnerships help to ensure a robust and diverse liquidity pool, providing users with the best possible trading conditions.',
        opened: false
    }
]