import styled from "styled-components";

export const Wrapper = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
`

export const FormRow = styled.div`
    width: 100%;
    input {
        width: 100%;
    }
    
    button {
        width: 100%;
    }
`

export const FormTitle = styled.h2``

export const FormDescription = styled.p.attrs({
    className: "text-lg"
})``