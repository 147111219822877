import React, { useEffect } from 'react';
// import {Navigate, Route, Routes} from "react-router";
import {Navigate, Route, Routes} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {WagmiProvider} from 'wagmi'

import AppProvider from "provider/AppProvider";

import Header from "layout/header/Header";
import Main from "layout/main/Main";

import {ProtectedRoute} from "./routes/ProtectedRoute";

import SwapPage from "pages/swap/SwapPage";
import Settings from "pages/settings/Settings";
import ApiKeys from "pages/settings/api-keys/ApiKeys";
import KycVerification from "pages/settings/kyc-verification/KycVerification";
import Notifications from "pages/settings/notifications/Notifications";

import {config} from "config/wagmi";
import {SkeletonTheme} from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {TooltipStyled} from "./styles/TooltipStyled";
import {EthereumProvider} from "./provider/EtherumProvider";
import FAQs from "./pages/faq/FAQs";
import Docs from "./pages/docs/Docs";
import PrivacyPolicy from "./pages/docs/privacy-policy/PrivacyPolicy";
import TermsOfService from "./pages/docs/terms-of-service/TermsOfService";
import Auth from 'pages/auth/Auth';
// import Login from 'pages/auth/login/Login';
// import Register from 'pages/auth/register/Register';
import { usePersistQueryParams } from 'hooks';
import Tasks from 'pages/quests/quests';
import Code from 'pages/code/code';
import ParticleNetworkConnectProvider from "./provider/ParticleNetworkConnectProvider";


const queryClient = new QueryClient()


const App = () => {
    const { checkQueryParams } = usePersistQueryParams();

    useEffect(() => {
        checkQueryParams();
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <WagmiProvider config={config}>
                <ParticleNetworkConnectProvider>
                    <AppProvider>
                        <EthereumProvider>
                            <SkeletonTheme baseColor={'#babac4'} highlightColor={'#54545e'}>
                                <Header/>
                                <Routes>
                                    <Route path={'/'} element={<Main/>}>
                                        <Route index element={<ProtectedRoute><Navigate to={'/swap'}/></ProtectedRoute>}/>
                                        <Route path={'swap'} element={<ProtectedRoute><SwapPage/></ProtectedRoute>}/>
                                        <Route path={'tasks'} element={<ProtectedRoute><Tasks/></ProtectedRoute>}/>
                                        <Route path={'secret-route'} element={<Code/>}/>
                                        {/*<Route path={'staking'} element={<ProtectedRoute><Staking/></ProtectedRoute>}>*/}
                                        {/*    <Route index element={<LandingPage/>}/>*/}
                                        {/*    <Route path={'check-status'} element={<CheckStatus/>}/>*/}
                                        {/*</Route>*/}
                                        <Route path={'faq'} element={<ProtectedRoute><FAQs/></ProtectedRoute>}/>
                                        <Route path={'settings'} element={<ProtectedRoute><Settings/></ProtectedRoute>}>
                                            <Route path={'api-keys'} element={<ApiKeys/>}/>
                                            <Route path={'kyc-verification'} element={<KycVerification/>}/>
                                            <Route path={'notifications'} element={<Notifications/>}/>
                                        </Route>
                                        <Route path={'/auth'} element={<Auth/>}>
                                           {/* <Route path={'login'} element={<Login/>}/> */}
                                           {/* <Route path={'register'} element={<Register/>}/> */}
                                        {/*    <Route path={'forgot-password'} element={<ForgotPassword/>}/>*/}
                                        {/*    <Route path={'set-new-password'} element={<SetNewPassword/>}/>*/}
                                        </Route>
                                        <Route path={'docs'} element={<Docs/>}>
                                            <Route path={'privacy-policy'} element={<PrivacyPolicy/>}/>
                                            <Route path={'terms-of-service'} element={<TermsOfService/>}/>
                                        </Route>
                                    </Route>
                                </Routes>
                            </SkeletonTheme>
                            <TooltipStyled id={'tooltip'}/>
                        </EthereumProvider>

                    </AppProvider>

            </ParticleNetworkConnectProvider>
            </WagmiProvider>
        </QueryClientProvider>

    );
}

export default App;
