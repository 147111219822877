import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const apiInstance = axios.create({
    baseURL: apiBaseUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const setApiInterceptor = (token: string | null) => {
    apiInstance.interceptors.request.use(
        async (request) => {
            if (token) {
                request.headers.Authorization = `Bearer ${token}`
            }
            return request
        }
    )
}