const replaceHtmlTags = (input: string): string => {
    return input.replace(/<[/]br>/g, '\n').replace(/&emsp;/g, '    ').replace(/<\/?[^>]+(>|$)/g, "");
}

export async function copyToClipboard (value: string) {
    const typeRich = 'text/html';
    const blobRich = new Blob([value], { type: typeRich });
    const typePlain = 'text/plain';
    const plainText = replaceHtmlTags(value);
    const blobPlain = new Blob([plainText], { type: typePlain });
    const data = [new ClipboardItem({ [typeRich]: blobRich, [typePlain]: blobPlain })];

    await navigator.clipboard.write(data);
}