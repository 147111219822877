import styled from "styled-components";

export const Wrapper = styled.div.attrs({
    className: 'text-md'

})`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 16px;
    background-color: ${({theme}) => theme.color.grayscale[600]};
    cursor: pointer;
    
    .account-address {
        background-color: ${({theme}) => theme.color.grayscale[800]};
        padding: 4px 8px;
        border-radius: 12px;
    }
`