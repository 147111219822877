import styled, {css, keyframes} from "styled-components";
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const Wrapper = styled.div`
    .running {
        svg {
            animation: ${rotate} 1s linear infinite;
        }

    }
    
   

    @media screen and (max-width: ${({theme}) => theme.breakingPoints.sm}){
        .transaction-history-option-switch-wrapper {
            width: 100%;
        }

        .transaction-history-option-item{
            flex: 1;
        }
    }
`

export const Title = styled.div`
    font-size: 1.25rem;
    line-height: ${({theme}) => theme.lineHeight.xs};
    font-weight: 700;
    margin-bottom: 24px;
`

export const TableWrapper = styled.div`
    max-width: 100%;
    overflow-x: auto;
    border-radius: 16px;

    
    .transaction-hash-column{
        display: flex;
        align-items: center;
        gap: 8px;
        
        svg {
            cursor: pointer;
            outline: none;
            path {
                fill: ${({theme}) => theme.color.grayscale[300]};
            }   
        }
    }
    
    @media screen and (max-width: ${({theme}) => theme.breakingPoints.md}){
        .footer-pagination-row{
            text-align: left;
        }
    }
`

export const StatusWrapper = styled.div<{status: string}>`
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    line-height: 16px;
    width: fit-content;
    
    svg {
        width: 16px;
        height: 16px;
    }
    
    ${({status, theme}) => {
        switch (status.toLowerCase()) {
            case 'pending':
                return css`
                    background-color: rgba(239, 243, 58, 0.16);
                    color: ${theme.color.yellow};
                `
            case 'running':
                return css`
                    background-color: rgba(58, 77, 243, 0.16);
                    color: ${theme.color.info};
                `
            case 'completed':
                return css`
                    background-color: rgba(84, 243, 58, 0.16);
                    color: ${theme.color.success};
                `
                
        }
    }}
`

export const MobileTableWrapper = styled.div.attrs({
    className: 'text-md'
})`
    border-radius: 16px;
    background-color: ${({theme}) => theme.color.grayscale[800]};
`

export const MobileTableItem = styled.div`
    padding: 16px;
    border-bottom: 1px solid ${({theme}) => theme.color.grayscale[300]};
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    
    .row {
        display: flex;
        justify-content: space-between;
    }
    
    .amount {
        color: ${({theme}) => theme.color.brand.yellow.regular};
    }
`

export const MobileTableFooter = styled.div`
    padding: 16px;
`

export const TransactionPathButton = styled.button`
    width: 100%;
    background-color: ${({theme}) => theme.color.grayscale[700]};
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({theme}) => theme.color.grayscale[100]};
    border-radius: 4px;
    cursor: pointer;
`