import React, {useCallback, useEffect, useRef} from "react";

import {Wrapper} from "./Container.style";

interface Props extends React.HTMLAttributes<HTMLDivElement>{
    toggleElementRef: React.RefObject<HTMLElement>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
}

const DropdownContainer: React.FC<Props> = ({toggleElementRef, isOpen, setIsOpen ,children, ...props}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    const updatePosition = useCallback(() => {
        if (toggleElementRef.current && dropdownRef.current) {
            const buttonRect = toggleElementRef.current.getBoundingClientRect();
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            const spaceBelow = viewportHeight - buttonRect.bottom;
            const spaceAbove = buttonRect.top;
            let topPosition: number = (dropdownRect.height < spaceBelow || spaceBelow >= spaceAbove) ?
                buttonRect.bottom + 10 : buttonRect.top - dropdownRect.height - 10;

            const spaceRight = viewportWidth - buttonRect.right;
            const spaceLeft = buttonRect.left;
            let leftPosition: number = (dropdownRect.width < spaceRight || spaceRight >= spaceLeft) ?
                buttonRect.left : buttonRect.right - dropdownRect.width;

            dropdownRef.current.style.top = `${topPosition}px`;
            dropdownRef.current.style.left = `${leftPosition}px`;
            dropdownRef.current.style.position = 'fixed';
        }
    }, [toggleElementRef]);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
            toggleElementRef.current && !toggleElementRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    }, [toggleElementRef, setIsOpen]);

    const handleToggleClick = useCallback((event: MouseEvent) => {
        if(toggleElementRef.current && toggleElementRef.current.contains(event.target as Node)){
            setIsOpen(prev => !prev);
        }
    }, [setIsOpen, toggleElementRef]);

    useEffect(() => {

        const toggleRef = toggleElementRef.current;

        document.addEventListener('mousedown', handleClickOutside);
        toggleRef?.addEventListener('click', handleToggleClick);
        window.addEventListener('resize', updatePosition);
        window.addEventListener('scroll', updatePosition, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            toggleRef?.removeEventListener('click', handleToggleClick);
            window.removeEventListener('resize', updatePosition);
            window.removeEventListener('scroll', updatePosition, true);
        };
    }, [handleClickOutside, updatePosition, handleToggleClick, toggleElementRef]);

    useEffect(() => {
        updatePosition();
    }, [isOpen, updatePosition]);

    return (
        <Wrapper $isOpen={isOpen} ref={dropdownRef} {...props}>
            {children}
        </Wrapper>
    );
}

export default DropdownContainer;