import React from 'react';

import DropdownContainer from "./container/Container";
import DropdownMenuList from "./menu-list/MenuList";
import {ReactComponent as KeyboardArrowDown} from "assets/svgs/KeyboardArrowDown.svg";

import {ToggleButton} from "./Dropdown.style";
import DropdownItem from "./item/Item";

interface DropdownProps {
    label?: string | React.ReactNode;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    toggleElementRef?: React.RefObject<HTMLElement>;
    toggleButtonClassName?: string;
    containerClassName?: string;
    children: React.ReactNode;
}


const Dropdown: React.FC<DropdownProps> = ({isOpen, setIsOpen, label, toggleElementRef, toggleButtonClassName, containerClassName, children}) => {

    const toggleButtonRef = React.useRef<HTMLButtonElement>(null);

    return <>
        {toggleElementRef ? null :<ToggleButton
            className={toggleButtonClassName}
            ref={toggleButtonRef}
        >
            <h3>{label}</h3>
            <KeyboardArrowDown/>
        </ToggleButton>}
        <DropdownContainer
            className={containerClassName}
            toggleElementRef={toggleElementRef ?? toggleButtonRef}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            {children}
        </DropdownContainer>
    </>
};

export {
    DropdownContainer,
    DropdownMenuList,
    DropdownItem
}

export default Dropdown;
