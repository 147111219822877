import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video, input {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
        box-sizing: border-box;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    html, body, #app, #app>div {
        height: 100%;

        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        ::-webkit-scrollbar-track {
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background-color: ${({theme}) => theme.color.grayscale[400]};
            border-radius: 4px;
        }
        
    }
    
    body {
        line-height: 1rem;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        background-color: ${({theme}) => theme.color.grayscale[900]};
        color: ${({theme}) => theme.color.grayscale[100]};
        letter-spacing: .5px;
        box-sizing: border-box;
        
    }

    button {
        border: none;
        outline: none;
        padding: 0;
        background: none;
        text-align: inherit;
        font-family: 'Poppins', sans-serif;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }

    h1, h2, h3, h4, h5 {
        font-weight: 600;
        letter-spacing: 0.25px;
        font-family: 'Poppins', sans-serif;
    }

    h1 {
        font-size: ${({theme}) => theme.fontSize.heading.h1};
        line-height: ${({theme}) => theme.lineHeight.xl};
    }

    h2 {
        font-size: ${({theme}) => theme.fontSize.heading.h2};
        line-height: ${({theme}) => theme.lineHeight.lg};
    }

    h3 {
        font-size: ${({theme}) => theme.fontSize.heading.h3};
        line-height: ${({theme}) => theme.lineHeight.md};
    }

    h4 {
        font-size: ${({theme}) => theme.fontSize.heading.h4};
        line-height: ${({theme}) => theme.lineHeight.sm};
    }

    h5 {
        font-size: ${({theme}) => theme.fontSize.heading.h5};
        line-height: ${({theme}) => theme.lineHeight.xs};
    }

    .text {
        font-weight: 500;
        &-lg {
            font-size: ${({theme}) => theme.fontSize.text.lg};
            line-height: ${({theme}) => theme.lineHeight.xs};
            letter-spacing: 0.25px;
        }

        &-md {
            font-size: ${({theme}) => theme.fontSize.text.md};
            line-height: ${({theme}) => theme.lineHeight.xs};
            letter-spacing: 0.5px;
        }
        
        &-sm {
            font-size: ${({theme}) => theme.fontSize.text.sm};
            letter-spacing: 0.25px;
        }
    }
    
    strong {
        font-weight: 700;
    }
    
    a {
        color: ${({theme}) => theme.color.brand.yellow.regular};
        cursor: pointer
    }

    /* Toast container */
    .Toastify__toast {
        display: flex;
        align-items: center; /* Vertically center all children */
        padding: 16px;
        background: rgba(84, 243, 58, 0.08);
        border-radius: 16px;
        color: ${({theme}) => theme.color.grayscale[100]};
        font-size: 16px;
        &--success {
            border: 1px solid ${({theme}) => theme.color.success};
            background-color: rgba(84, 243, 58, 0.08);
        }
        
        &--error {
            border: 1px solid ${({theme}) => theme.color.error};
            background-color: rgba(243, 58, 58, 0.08);
        }
        
        &--warning {
            border: 1px solid ${({theme}) => theme.color.warning};
            background-color: rgba(243, 147, 58, 0.08);
        }
        
        &--info {
            background-color: rgba(58, 77, 243, 0.08);
            border: 1px solid ${({theme}) => theme.color.info};
        }
    }

    .Toastify__toast-container {
        width: 500px;
        max-width: 100%;
        padding: 0;
    }

    .Toastify__toast-body {
        padding: 0;
        div:last-child {
           line-height: 1.25; 
        }
    }

    /* Toast icon */
    .Toastify__toast-icon {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px; /* Adjust the icon size as needed */
    }

    /* Toast message */
    .Toastify__toast-body {
        flex: 1;
    }

    /* Close button */
    .Toastify__close-button {
        color: ${({theme}) => theme.color.grayscale[100]};
        font-size: 20px; /* Adjust the close button size as needed */
        background: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0;
        margin: auto;
    }
    
    .styled-tooltip {
        background-color: ${({theme}) => theme.color.grayscale[600]} !important;
        padding: 4px 8px !important;
        border-radius: 8px  !important;
        font-size: 12px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
    }

`