import {ThemeProvider} from "styled-components";
import React from "react";

export const theme = {
    color: {
        grayscale: {
            0: '#FFFFFF',
            100: '#F6F6F6',
            200: '#EEEEEE',
            300: '#BABABA',
            400: '#979797',
            500: '#757575',
            600: '#525252',
            700: '#353535',
            800: '#1F1F1F',
            900: '#141414',
        },
        brand: {
            yellow: {
                light: '#F5E396',
                dark: '#A49245',
                regular: '#F1D665',
            },
            orange: {
                light: '#F7A08F',
                dark: '#A54F3D',
                regular: '#F3745A',
            },
        },
        info: '#3A4DF3',
        yellow: '#EFF33A',
        warning: '#F3933A',
        error: '#F33A3A',
        success: '#54F33A',
    },
    fontSize: {
        heading: {
            h1: '3rem',
            h2: '2rem',
            h3: '1.5rem',
            h4: '1.25rem',
            h5: '1rem',
        },
        text: {
            lg: '1rem',
            md: '0.875rem',
            sm: '0.75rem',
        }
    },
    lineHeight: {
        xl: '3.75rem',
        lg: '3rem',
        md: '2rem',
        sm: '1.75rem',
        xs: '1.5rem',
    },
    breakingPoints: {
        sm: '600px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
    }
}

interface Props {
    children: React.ReactNode
}

const Theme: React.FC<Props> = ({children}) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme