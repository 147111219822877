import React from "react";
import ToggleSwitch from "components/toggle-switch/ToggleSwitch";
import {useSettings} from "../hooks/useSettings";

const Notifications: React.FC = () => {

    const  {
        dontReceiveSomething,
        setDontReceiveSomething,
        receiveNewsletters,
        handleUpdateMarketingPreferences
    } = useSettings()

    return (
        <>
            <h3>Manage Notifications</h3>
            <h4>Email Newsletter subscription</h4>
            <div>
                <ToggleSwitch
                    label={'I want to receive email newsletter subscription'}
                    checked={receiveNewsletters}
                    onChange={(event) => handleUpdateMarketingPreferences(event.target.checked)}
                />
                <ToggleSwitch
                    label={'I don’t want to receive something'}
                    checked={dontReceiveSomething}
                    onChange={(event) => setDontReceiveSomething(event.target.checked)}
                />
            </div>
        </>
    )
}

export default Notifications