import React, {useEffect, useState} from "react";
import Dropdown, {DropdownItem} from "components/dropdown/Dropdown";
import {useAppContext, useWallet} from "hooks";
import {useWalletService} from "queries";
import {ReactComponent as KeyboardArrowDown} from "assets/svgs/KeyboardArrowDown.svg";
import {NetworkSelectItem, NetworkSelectToggleButton} from "./NetworkSelect.style";
import {useSwitchChain} from "wagmi";
import {Chain} from "viem";
import {ReactComponent as BitcoinIcon} from "assets/svgs/BitcoinNetworkIcon.svg";
import {toast} from "react-toastify";
import ChainIcon from "components/chain-icon/ChainIcon";

interface Props {
    toggleButtonClassName?: string
    containerClassName?: string
    itemClassName?: string
}

const NetworkSelect: React.FC<Props> = ({toggleButtonClassName,itemClassName, containerClassName}) => {
    const [networkSelectOpened ,setNetworkSelectOpened] = useState(false)

    const {selectedChain, setYouGiveAmount} = useAppContext()

    const toggleRef = React.useRef<HTMLButtonElement>(null)

    const { chains, switchChainAsync , switchChain} = useSwitchChain()
    const {address} = useWallet()

    const updateConnectedWallet = useWalletService()

    //Set the default network to Bitlayer
    useEffect(() => {
        if(!selectedChain){
            switchChain({chainId: 200901})
        }
    }, [switchChain, selectedChain])

    const handleNetworkSelect = async (chain: Chain) => {
        try {
            const data = await switchChainAsync({chainId: chain.id})
            updateConnectedWallet.mutate({
                    address: address || '',
                    chain: String(data.id)
                }
            )
            setYouGiveAmount('1')
            toast('Network switched successfully', {type: 'success'})
        } catch (e) {
            toast('Failed to switch network', {type: 'error'})
        } finally {
            setNetworkSelectOpened(false)
        }
    }


    return (
        <>
            <NetworkSelectToggleButton ref={toggleRef} className={toggleButtonClassName}>
                <div style={{display: 'flex', gap: '8px'}}>
                <ChainIcon chain={selectedChain}/>
                {selectedChain?.name}
                </div>
                <KeyboardArrowDown/>
            </NetworkSelectToggleButton>
            <Dropdown
                toggleElementRef={toggleRef}
                isOpen={networkSelectOpened}
                setIsOpen={setNetworkSelectOpened}
                containerClassName={containerClassName}
            >
                <p className={'text-lg'} style={{marginBottom: '8px'}}>Networks</p>
                <NetworkSelectItem className={itemClassName} selected={false} disabled={true}>
                    <BitcoinIcon/> Bitcoin
                </NetworkSelectItem>
                {chains.map((chain) => (
                    <NetworkSelectItem
                        className={itemClassName}
                        selected={chain.id === selectedChain?.id}
                        key={chain?.id}
                        onClick={() => handleNetworkSelect(chain)}
                    >
                        <ChainIcon chain={chain}/> {chain.name}
                    </NetworkSelectItem>
                ))}
            </Dropdown>
        </>

    )
}

export default NetworkSelect
