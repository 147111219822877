import styled, {css, keyframes} from "styled-components";

const fadeInSlideDown = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

interface Props {
    $isOpen: boolean;
}

export const Wrapper = styled.div<Props>`
    position: absolute;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid ${({theme}) => theme.color.grayscale[300]};
    background-color: ${({theme}) => theme.color.grayscale[800]};
    overflow-y: auto;
    max-width: 100%;
    z-index: 100;
    display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
    ${({$isOpen}) => $isOpen && css`
        animation: ${fadeInSlideDown} 0.3s ease-out;
    `}
`