import styled from "styled-components";
import {NavLink} from "react-router-dom";

export const Wrapper = styled.footer`
    //width: calc(100% - 120px); 
    margin-top: 60px;
    width: 100%;
    color: ${({theme}) => theme.color.grayscale[300]};
    font-size: 14px;
    line-height: 24px;
    
    .label {
        color: ${({theme}) => theme.color.grayscale[100]};
        font-weight: 700;
    }
    .socials {
        rect {
            fill: ${({theme}) => theme.color.grayscale[100]};
        }
    }
    @media (max-width: ${({theme}) => theme.breakingPoints.md}) {
        padding-bottom: 100px;
    }
`

export const TopSectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid ${({theme}) => theme.color.grayscale[400]};
    gap: 40px;
    
    .mail-link{
        color: ${({theme}) => theme.color.grayscale[300]};
        font-weight: 500;
        font-size: 14px;
        text-decoration: underline;
        &:hover {
            color: ${({theme}) => theme.color.grayscale[100]};
        }
    }
    
    .socials-icons{
        display: flex;
        gap: 16px;
        align-items: center;
    }
    
    .column {
        flex: 1;
        display: flex;
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
        
        &.socials {
            gap: 24px;
        }
    }

    @media (max-width: ${({theme}) => theme.breakingPoints.md}) {
        flex-direction: column;
    }
`

export const FooterLink = styled(NavLink)`
    color: ${({theme}) => theme.color.grayscale[300]};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
    &:hover {
        color: ${({theme}) => theme.color.grayscale[100]};
    }
    &.active {
        color: ${({theme}) => theme.color.grayscale[100]};
    }
`

export const BottomSectionWrapper = styled.div`
    // padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    gap: 24px;

    border-top: 1px solid ${({theme}) => theme.color.grayscale[400]};

    .copyright {
        color: ${({theme}) => theme.color.grayscale[300]};
        font-weight: 500;
        font-size: 14px;
    }

    .mobile-footer {
        display: none;
    }

    .network-select-toggle {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .network-select-container {
        width: calc(100% - 32px);
    }

    .network-select-item {
        width: 100%;
    }
    
    .links {
        display: flex;
        gap: 24px;
    }
    
    a {
        color: ${({theme}) => theme.color.grayscale[300]};
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        &:hover {
            color: ${({theme}) => theme.color.grayscale[100]};
        }
    }

    @media (max-width: ${({theme}) => theme.breakingPoints.md}) {
        flex-direction: column;
        align-items: flex-start;
        .links{
            flex-direction: column;
        }
    }
`

export const MobileFooterButtonGroup = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({theme}) => theme.color.grayscale[900]};
        padding: 24px 16px;
        display: flex;
        gap: 20px;
        align-items: center;
        width: 100%;
        .network-select-toggle {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .network-select-container {
            width: calc(100% - 32px);
        }

        .network-select-item {
            width: 100%;
        }
    
`