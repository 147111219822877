import styled from "styled-components";

export const Wrapper = styled.button`
    padding: 8px;
    border-radius: 16px;
    background-color: ${({theme}) => theme.color.grayscale[600]};
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
`