import React from "react";

import {ReactComponent as MetamskIcon} from "assets/svgs/MetaMaskIcon.svg";
import {ReactComponent as WalletConnectIcon} from "assets/svgs/WalletConnectIcon.svg";
import {ReactComponent as OpenBitIcon} from "assets/svgs/open-bit.svg";
import {ReactComponent as UnisatIcon} from "assets/svgs/UnisatIcon.svg";
import {ReactComponent as OKXIcon} from "assets/svgs/OKXWallet.svg";

interface Props extends React.SVGProps<SVGSVGElement> {
    name: string
}

const WalletIcon: React.FC<Props> = ({name, ...rest}) => {
    switch (name.toLowerCase()) {
        case 'metamask':
            return <MetamskIcon {...rest}/>
        case 'walletconnect':
            return <WalletConnectIcon {...rest}/>
        case 'openbit':
            return <OpenBitIcon style={{width: '24px', height: '24px'}} {...rest} />
        case 'unisat wallet':
            return <UnisatIcon {...rest}/>
        case 'okx wallet':
            return <OKXIcon {...rest}/>
        default:
            return  <MetamskIcon {...rest}/>

    }
}

export default WalletIcon;