import React from "react";

import {Outlet} from "react-router-dom";

import Alert from "components/alert/Alert";
import {useAuthContext} from "hooks";

import {Content, Wrapper} from './Auth.style';


const Auth: React.FC = () => {
    const {success, error, setError, setSuccess} = useAuthContext()
    return <Wrapper>
        {success ? <Alert
            style={{width: '636px', maxWidth: '100%'}}
            type={'success'}
            onClose={() => setSuccess(undefined)}
        >
            {success}
        </Alert> : null}
        {error ? <Alert
            onClose={() => setError(undefined)}
            style={{width: '636px', maxWidth: '100%'}}
            type={'error'}
        >
            {error}
        </Alert> : null}
        <Content>
            <Outlet/>
        </Content>
    </Wrapper>
}

export default Auth;