import styled, {keyframes} from "styled-components";
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const TableStyled = styled.table`
    width: 100%;
    background-color: ${({theme}) => theme.color.grayscale[800]};
    border-radius: 16px;
    border-collapse: collapse;
    
    
`;

export const TableHeadStyled = styled.thead`
    height: 72px;
    
    tr:first-child {
        border: none;
    }
`;

export const TableBodyStyled = styled.tbody``;

export const TableRowStyled = styled.tr`
    border-top: 1px solid ${({theme}) => theme.color.grayscale[300]};
    
`;

export const TableCellStyled = styled.td.attrs({
    className: 'text-md'
})`
    padding: 16px 16px;
    vertical-align: middle;
    font-weight: 500;
`;

export const TableHeadCellStyled = styled.th.attrs({
    className: 'text-lg'
})`
    padding: 0 16px;
    text-align: left;
    vertical-align: middle;
    font-weight: 600;
`;

export const TableFooterStyled = styled.tfoot`
    height: 72px;
    border-top: 1px solid ${({theme}) => theme.color.grayscale[300]};
    td {
        text-align: right;
    }
`


