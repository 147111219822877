
const digest = 'a81c744751497ed4b401c08b9e5aeef1991aeb86f2cbba3ee57c1926c0502f1a';
const classes = {"detailsCard":"_detailsCard_ml2p9_1","title":"_title_ml2p9_13","nftContract":"_nftContract_ml2p9_19","row":"_row_ml2p9_26","tokenIcon":"_tokenIcon_ml2p9_35","nftIcon":"_nftIcon_ml2p9_41","balanceChange":"_balanceChange_ml2p9_49","tokenSymbol":"_tokenSymbol_ml2p9_56","toContract":"_toContract_ml2p9_61","right":"_right_ml2p9_71"};
const css = `._detailsCard_ml2p9_1{background:#232529;border:1px solid #252525;border-radius:8px;color:#fff;margin-top:16px;overflow-x:hidden;padding:10px;position:relative;width:100%}._detailsCard_ml2p9_1 ._title_ml2p9_13{font-size:16px;font-weight:600;margin-bottom:12px;text-align:left}._detailsCard_ml2p9_1 ._nftContract_ml2p9_19{color:#a855f7;font-size:16px;position:absolute;right:8px;top:8px}._detailsCard_ml2p9_1 ._row_ml2p9_26{align-items:center;display:flex;flex-direction:row;font-size:14px;gap:8px;justify-content:space-between;margin-top:6px}._detailsCard_ml2p9_1 ._row_ml2p9_26 ._tokenIcon_ml2p9_35{border-radius:50%;height:32px;margin:10px 0;width:32px}._detailsCard_ml2p9_1 ._row_ml2p9_26 ._nftIcon_ml2p9_41{border-radius:2px;display:inline;height:32px;margin:10px 8px 10px 0;width:32px}._detailsCard_ml2p9_1 ._row_ml2p9_26 ._balanceChange_ml2p9_49{flex-grow:2;font-size:16px;font-weight:500;text-align:end;word-break:break-word}._detailsCard_ml2p9_1 ._row_ml2p9_26 ._tokenSymbol_ml2p9_56{font-size:16px;font-weight:500;text-align:end}._detailsCard_ml2p9_1 ._row_ml2p9_26 ._toContract_ml2p9_61{align-items:center;display:flex;gap:8px}._detailsCard_ml2p9_1 ._row_ml2p9_26 ._toContract_ml2p9_61 img{display:inline;height:18px;width:18px}._detailsCard_ml2p9_1 ._row_ml2p9_26 ._right_ml2p9_71{text-align:end}`;

(function() {
  if (typeof document !== "undefined" && !document.getElementById(digest)) {
    var ele = document.createElement('style');
    ele.id = digest;
    ele.textContent = css;
    document.head.appendChild(ele);
  }
})();

export default classes;
export { css, digest, classes };
  