import { usePersistQueryParams } from "hooks";
import React, { useEffect } from "react";

import { Wrapper } from "./quests.style";

const Tasks: React.FC = () => {
    const {setQueryParam} = usePersistQueryParams();

    // useEffect(() => {
    //     const token = window.localStorage.getItem("claimr-token");
    //     if (token) {
    //         const script = document.getElementById("claimr-script");
    //         script?.setAttribute("data-user-token", token);
    //     }
    //     const hasReloaded = window.localStorage.getItem("has-reloaded");
    //     if(hasReloaded === "false") {
    //         window.localStorage.setItem("has-reloaded", "true");
    //         window.location.reload()
    //     }
    // }, []);

    useEffect(() => {
        const paramValue = window.localStorage.getItem('param');
        if(paramValue) {
            setQueryParam('ref', paramValue);
        }
    }, []);

    return (
        <Wrapper>
            <h3>Beta Testing Vault</h3>
            <div id="myClaimrWidget" />
        </Wrapper>
    )
}

export default Tasks