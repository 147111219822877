import styled from "styled-components";
import DropdownItem from "../../components/dropdown/item/Item";

export const NetworkSelectToggleButton = styled.button.attrs({
    className: 'text-lg'
})`
    cursor: pointer;
    padding: 10px 14px;
    border-radius: 16px;
    color: ${({theme}) => theme.color.grayscale[200]};
    display: flex;
    align-items: center;
    gap: 8px;
    border: 2px solid ${({theme}) => theme.color.grayscale[200]};
    white-space: nowrap;
`

export const NetworkSelectItem = styled(DropdownItem)`
    width: 200px;
`