import {TNetwork} from "types/types";

export const BREAKING_POINTS = {
    sm: 600,
    md: 768,
    lg: 992,
    xl: 1200,
}

export const NETWORKS: {name: string, value: TNetwork}[] = [
    {
        name: 'Bitcoin',
        value: 'btc'
    },
    {
        name: 'Bitlayer',
        value: 'bitlayer'
    },
    // {
    //     name: 'Rootstock',
    //     value: 'rootstock'
    // },
    {
        name: 'Core',
        value: 'core'
    }
]

export const TRANSACTION_HISTORY_OPTIONS = [
    {
        value: 'mine',
        label: 'Mine'
    },
    {
        value: 'all',
        label: 'All'
    },

]

export const SLIPPAGE_OPTIONS = [
    {
        label: '0.5% Auto',
        value: 0.5
    },
    {
        label: '1%',
        value: 1
    },
    {
        label: '5%',
        value: 5
    },
    {
        label: 'Custom',
        value: 'custom'
    }
]

export const SWAP_TYPE = [
    {
        label: 'BRC 20',
        value: 'brc_20'
    },
    {
        label: 'Runes',
        value: 'runes'
    },
    {
        label: 'Other',
        value: 'other'
    },

]
