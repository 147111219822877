import React from "react";

import {FormDescription, FormRow, FormTitle, Wrapper} from "./Form.style";

interface Props {
    children: React.ReactNode;
}

const Form = ({children, ...rest}: Props & React.ComponentProps<'form'>) => {
    return <Wrapper {...rest}>
        {children}
    </Wrapper>
}

const Row: React.FC<Props & React.ComponentProps<'div'>> = ({ children, ...rest }) => {
    return <FormRow {...rest}>{children}</FormRow>;
};

const Title: React.FC<Props & React.ComponentProps<'h2'>> = ({ children, ...rest }) => {
    return <FormTitle {...rest}>{children}</FormTitle>;
};

const Description: React.FC<Props & React.ComponentProps<'p'>> = ({ children, ...rest }) => {
    return <FormDescription {...rest}>{children}</FormDescription>;
};

Form.Row = Row;
Form.Title = Title;
Form.Description = Description;


export default Form;