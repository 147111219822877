import React from "react";
import {Wrapper} from "./Item.style";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    selected: boolean;
    disabled?: boolean;
}

const DropdownItem: React.FC<Props> = ({selected, disabled, children, ...props}) => {
    return <Wrapper $disabled={disabled} $active={selected} {...props}>{children}</Wrapper>
}

export default DropdownItem;