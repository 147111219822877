import React from "react";
import {Outlet} from "react-router-dom";
import {Wrapper} from "./Main.style";
import ConnectWallet from "modals/connect-wallet/ConnectWallet";
import ConfirmSwap from "../../modals/confirm-swap/ConfirmSwap";
import TransactionPath from "../../modals/transaction-path/TransactionPath";
import Footer from "../footer/Footer";

const Main: React.FC = () => {
    return (<Wrapper >
        <Outlet/>
        <Footer/>
        <ConnectWallet/>
        <ConfirmSwap/>
        <TransactionPath/>
    </Wrapper>)
}

export default Main;