import styled from "styled-components";

export const Wrapper = styled.div`
    max-width: 100%;
    width: 630px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 40px;

    p, li{
        font-weight: 400;
    }

    ul {
        list-style: disc;
        padding-left: 24px;
        :last-child {
            margin-bottom: 0;
        }
        li {
            margin-bottom: 24px;
        }
    }
`
