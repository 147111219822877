import styled from "styled-components";

export const Wrapper = styled.div`

`;

export const SwapSection = styled.section`
    display: flex;
    gap: 32px;
`

export const TransactionsSection = styled.section`
    margin-top: 64px;
`